import * as React from 'react';
import Button from '@mui/material/Button';
import InterestsIcon from '@mui/icons-material/Interests';

function AudienceFooter({totalSlectionCount, onChildClick, onCalculate, count, onNextClick}) {
    const handleClearAll = () => {
        // Call the function passed from the parent component
        onChildClick('Value from child');
      };

    //   const onNextClick = () => {
    //     alert();
    //   }

    return (
        <div className='demo-ft-card'>
             <div className='first-item'>
                <Button variant="text" onClick={handleClearAll}>Clear</Button>
            </div>
            <div className='center-item'>
               <InterestsIcon></InterestsIcon> {totalSlectionCount} Categories {convertCount(count)}
            </div>
            <div className='last-item'>
                <Button variant="outlined" onClick={onCalculate}>Calculate</Button>
                <Button variant="contained" onClick={onNextClick}>Next</Button>
            </div>
                
        </div>
    );

    function convertCount(count) {
        let convertedCount = count;
        if (count >= 1000000) {
          convertedCount = (count / 1000000).toFixed(2) + "M";
        } else if (count >= 1000) {
          convertedCount = (count / 1000).toFixed(2) + "K";
        } else {
          convertedCount = count;
        }
        return convertedCount;
      }
}
export default AudienceFooter;
