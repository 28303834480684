import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const ProjectDialog = ({ open, onClose, onYesClick, maintitle, subtext, buttonvalue, success, error, isSuccess }) => {
  const [projectName, setProjectName] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  useEffect(() => {
    // Reset buttonClicked state to false if error occurs
    if (error) {
      setButtonClicked(false);
    }
  }, [error]);

  const handleButtonClick = () => {
    setButtonClicked(true); // Set buttonClicked to true when the button is clicked
    onYesClick(); // Call the onYesClick function
  };

  const renderSVG = () => {
    if (isSuccess == 'true') {
      return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
          <path d="M38 27.0799V27.9999C37.9988 30.1563 37.3005 32.2545 36.0093 33.9817C34.7182 35.7088 32.9033 36.9723 30.8354 37.5838C28.7674 38.1952 26.5573 38.1218 24.5345 37.3744C22.5117 36.6271 20.7847 35.246 19.611 33.4369C18.4373 31.6279 17.8798 29.4879 18.0217 27.3362C18.1636 25.1844 18.9972 23.1362 20.3983 21.4969C21.7994 19.8577 23.6928 18.7152 25.7962 18.24C27.8996 17.7648 30.1003 17.9822 32.07 18.8599M38 19.9999L28 30.0099L25 27.0099" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      );
    } else {
      return (
        <svg width="48" height="48" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" />
          <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className='modal-delete'>
      {renderSVG()}
      <DialogTitle className='modal-lg-text'>{maintitle}</DialogTitle>
      <DialogContent className='modal-sm-text'>{subtext}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">Cancel</Button>
        <Button onClick={handleButtonClick} variant="contained" 
        className={isSuccess === 'true' ? 'success-button' : 'primary-button'}  disabled={buttonClicked}>
          {buttonvalue}
        </Button>
      </DialogActions>
      {success && (
        <div className="success-message">{success}</div>
      )}

      {error && (
        <div className="error-message">{error}</div>
      )}
    </Dialog>
  );
};

export default ProjectDialog;
