import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import APIS from './APIS'
import { useNavigate } from "react-router-dom";

const EmailVerify = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const otp = queryParams.get('otp'); 
        const email = queryParams.get('email'); 
        const refID = queryParams.get('refId'); // Get the 'id' query parameter from the URL
       
        const data = {
          "otp": otp,
          "refCode": refID,
          "email": email
        }

        const response = await APIS.ConfirmEmailResponse(data);
        if (response.code == 200) {
          const displaydata = { text1: 'We have received your request', 
          text2: 'Verify your email address', 
          text3: 'your account is waiting for our administrator approval. Please check back later',
          text4: ''};
          
          navigate('/PageInfoModalBox', { state: displaydata });
          // const changeStatusData = {
          //   "status": "Active",
          //   "email": email
          // }
          
        // const responsechangeStatus = await APIS.ChangeRegistrationUserStatus(changeStatusData);
        // if (response.code == 200) {
        //   alert('call creat pwd');
        // }
        } else{
          const displaydata = { text1: response.message, 
          text2: '', 
          text3: '',
          text4: ''};
          navigate('/PageInfoModalBox', { state: displaydata });
        }
      } catch (error) {
        alert(error);
        //setRegistrationStatus({ success: null, error: error });
      }
    };

    fetchData();
  }, [location.pathname]); // Run this effect whenever the pathname changes
  

  return (
    <div>
      <h1>EmailVerify</h1>
    </div>
  );
};

export default EmailVerify;
