import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '../Components/TabPanel';
import Grid from '@mui/material/Grid';
import LocationCheckboxes from './LocationCheckboxes';
import Demographics from './Demographics';
import CircularProgress from '@mui/material/CircularProgress';
import CustomerInterest from './CustomerInterest';
import AudienceFooter from './AudienceFooter';
import CommercialRelevant from './CommercialRelevant';

const TabAudience = ({demographicsData, loaderDemoData,
                      custInterestData,loaderCustInterestData,
                      locationObj, loading,
                      commRelevantData, loaderCommRelData,
                      demographicsselectedFilters, setDemographicsselectedFilters,
                      custInterestselectedFilters, setCustInterestselectedFilters,
                      selectedRegions, setSelectedRegions,
                      selectedProvinces,setSelectedProvinces,
                      selectedDistricts,setSelectedDistricts,
                      commRelevantselectedFilters, setCommRelevantselectedFilters,
                      provinceDistrictCount, setProvinceDistrictCount,
                      regionProvinceCount, setRegionProvinceCount,
                      locationTrafficData, setLocationTrafficData,
                      websiteTrafficData, setWebsiteTrafficData,
                      showNextTab
                    }) => {
  
  const [subTabValue, setSubTabValue] = useState(0);
  
  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  const handleNextTab1 = () => {
    alert();
  }
  return (
    <Grid container spacing={2} className='content-container'>
      <Grid item xs={3}>
        <div className='content-card'>
          <div className='sec-header-title'>What audience are you trying to find? </div>
          <Tabs value={subTabValue} onChange={handleSubTabChange} orientation="vertical" className='border-tabs'>
            <Tab label="Demographics" value={0} />
            <Tab label="Customer Interest" value={1} />
            <Tab label="Geographic" value={2} className='sel-tab' />
            <Tab label=" Commercial Relevant" value={3} />
          </Tabs>
        </div>
      </Grid>
      <Grid item xs={9}>
        <div className='content-card3'>

          <TabPanel value={subTabValue} index={0}>
            {loaderDemoData ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>) :
              (<Demographics demographicsData={demographicsData}
                setDemographicsselectedFilters={setDemographicsselectedFilters} demographicsselectedFilters={demographicsselectedFilters}
                setCustInterestselectedFilters={setCustInterestselectedFilters} custInterestselectedFilters={custInterestselectedFilters}
                setSelectedRegions={setSelectedRegions} selectedRegions={selectedRegions}
                setSelectedProvinces={setSelectedProvinces} selectedProvinces={selectedProvinces}
                setSelectedDistricts={setSelectedDistricts} selectedDistricts={selectedDistricts}
                setProvinceDistrictCount={setProvinceDistrictCount} provinceDistrictCount={provinceDistrictCount}
                setRegionProvinceCount={setRegionProvinceCount} regionProvinceCount={regionProvinceCount}
                setCommRelevantselectedFilters={setCommRelevantselectedFilters} commRelevantselectedFilters={commRelevantselectedFilters}
                setLocationTrafficData={setLocationTrafficData} locationTrafficData={locationTrafficData}
                setWebsiteTrafficData={setWebsiteTrafficData} websiteTrafficData={websiteTrafficData}
                handleNextTab={showNextTab}></Demographics>)
            }
          </TabPanel>
          <TabPanel value={subTabValue} index={1}>
          {loaderCustInterestData ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>) :
              (<CustomerInterest custInterestData={custInterestData} 
                setDemographicsselectedFilters={setDemographicsselectedFilters} demographicsselectedFilters={demographicsselectedFilters}
                setCustInterestselectedFilters={setCustInterestselectedFilters} custInterestselectedFilters={custInterestselectedFilters}
                setSelectedRegions={setSelectedRegions} selectedRegions={selectedRegions}
                setSelectedProvinces={setSelectedProvinces} selectedProvinces={selectedProvinces}
                setSelectedDistricts={setSelectedDistricts} selectedDistricts={selectedDistricts}
                setProvinceDistrictCount={setProvinceDistrictCount} provinceDistrictCount={provinceDistrictCount}
                setRegionProvinceCount={setRegionProvinceCount} regionProvinceCount={regionProvinceCount}
                setCommRelevantselectedFilters={setCommRelevantselectedFilters} commRelevantselectedFilters={commRelevantselectedFilters}
                setLocationTrafficData={setLocationTrafficData} locationTrafficData={locationTrafficData}
                setWebsiteTrafficData={setWebsiteTrafficData} websiteTrafficData={websiteTrafficData}
                handleNextTab={showNextTab}>
                </CustomerInterest>)
            }
          </TabPanel>
          <TabPanel value={subTabValue} index={2}>
            <div className='sec-header-title'>Geographics </div>


            {loading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            ) : (
              <div><LocationCheckboxes locationObj={locationObj}
              setDemographicsselectedFilters={setDemographicsselectedFilters} demographicsselectedFilters={demographicsselectedFilters}
                setCustInterestselectedFilters={setCustInterestselectedFilters} custInterestselectedFilters={custInterestselectedFilters}
                setSelectedRegions={setSelectedRegions} selectedRegions={selectedRegions}
                setSelectedProvinces={setSelectedProvinces} selectedProvinces={selectedProvinces}
                setSelectedDistricts={setSelectedDistricts} selectedDistricts={selectedDistricts}
                setProvinceDistrictCount={setProvinceDistrictCount} provinceDistrictCount={provinceDistrictCount}
                setRegionProvinceCount={setRegionProvinceCount} regionProvinceCount={regionProvinceCount}
                setCommRelevantselectedFilters={setCommRelevantselectedFilters} commRelevantselectedFilters={commRelevantselectedFilters}
                setLocationTrafficData={setLocationTrafficData} locationTrafficData={locationTrafficData}
                setWebsiteTrafficData={setWebsiteTrafficData} websiteTrafficData={websiteTrafficData}
                handleNextTab={showNextTab}/>
                </div>
            )}

          </TabPanel>
          <TabPanel value={subTabValue} index={3}>
          {loaderCommRelData ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>) :
              (<CommercialRelevant commRelevantData={commRelevantData}
                setDemographicsselectedFilters={setDemographicsselectedFilters} demographicsselectedFilters={demographicsselectedFilters}
                setCustInterestselectedFilters={setCustInterestselectedFilters} custInterestselectedFilters={custInterestselectedFilters}
                setSelectedRegions={setSelectedRegions} selectedRegions={selectedRegions}
                setSelectedProvinces={setSelectedProvinces} selectedProvinces={selectedProvinces}
                setSelectedDistricts={setSelectedDistricts} selectedDistricts={selectedDistricts}
                setProvinceDistrictCount={setProvinceDistrictCount} provinceDistrictCount={provinceDistrictCount}
                setRegionProvinceCount={setRegionProvinceCount} regionProvinceCount={regionProvinceCount}
                setCommRelevantselectedFilters={setCommRelevantselectedFilters} commRelevantselectedFilters={commRelevantselectedFilters}
                setLocationTrafficData={setLocationTrafficData} locationTrafficData={locationTrafficData}
                setWebsiteTrafficData={setWebsiteTrafficData} websiteTrafficData={websiteTrafficData}
                handleNextTab={showNextTab}>
                </CommercialRelevant>)
            }
          </TabPanel>
        </div>
      </Grid>
    </Grid>
  );
};

export default TabAudience;