import React, { useEffect } from "react";
import Registration from "./Pages/Registration";
import Login from "./Pages/Login";
import Grid from "@mui/material/Grid";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Terms from "./Pages/Terms";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import ListOrderPage from './Pages/ListOrderPage';
import "./App.css";
import CreatePassword from "./Pages/CreatePassoword";
import ResetPassword from "./Pages/ResetPassword";
import ProjectList from "./Pages/ProjectList";
import Audience from "./Pages/Audience";
import AudienceList from "./Pages/AudienceList";
import CreateAudience from "./Pages/CreateAudience";
import Demographics from "./Pages/Demographics";
import CustomerInterest from "./Pages/CustomerInterest";
import CustomerRelevant from "./Pages/CommercialRelevant";
import EmailVerify from "./Pages/EmailVerify";
import ForgotPassword from "./Pages/ForgotPassword";
import PageInfoModalBox from "./Components/PageInfoModalBox";
import UpdateUserRegisterStatus from "./Pages/UpdateUserRegisterStatus";
import ChannelSetup from "./Pages/ChannelSetup";
import ChannelStatic from "./Pages/ChannelStatic";
import AudiencePerformPush from "./Pages/AudiencePerformPush";
import { RegisterProvider } from "./Components/RegisterContext";
import MarketingConsent from "./Pages/MarketingConsent";
import Home from "./Pages/Home";
import { AuthContextProvider } from "./Components/AuthContextProvider";
import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
  return (
    <div>
      <Grid container>
        <RegisterProvider>
          <BrowserRouter>
            <AuthContextProvider>
              <Grid item xs={12}>
                <div className="container">
                  <Routes>
                    <Route path="Home" element={<Home />} />
                    <Route path="Registration" element={<Registration />} />
                    <Route path="Login" element={<Login />} />
                    <Route path="Terms" element={<Terms />} />
                    <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
                    <Route element={<ProtectedRoute roles={["user"]} />}>
                      <Route path="ChannelStatic" element={<ChannelStatic />} />
                      <Route
                        path="CreatePassword"
                        element={<CreatePassword />}
                      />
                      <Route path="ChannelSetup" element={<ChannelSetup />} />
                      <Route path="Audience" element={<Audience />} />
                      <Route
                        path="/AudiencePerformPush/:id"
                        element={<AudiencePerformPush />}
                      />
                      <Route path="AudienceList" element={<AudienceList />} />
                      <Route
                        path="CreateAudience"
                        element={<CreateAudience />}
                      />
                      <Route path="Demographics" element={<Demographics />} />
                      <Route
                        path="CustomerInterest"
                        element={<CustomerInterest />}
                      />
                      <Route
                        path="CustomerRelevant"
                        element={<CustomerRelevant />}
                      />
                    </Route>

                    <Route path="ResetPassword" element={<ResetPassword />} />

                    <Route
                      path="MarketingConsent"
                      element={<MarketingConsent />}
                    />

                    <Route path="EmailVerify" element={<EmailVerify />} />
                    <Route
                      path="UpdateUserRegisterStatus"
                      element={<UpdateUserRegisterStatus />}
                    />
                    <Route
                      path="PageInfoModalBox"
                      element={<PageInfoModalBox />}
                    />
                    <Route path="ForgotPassword" element={<ForgotPassword />} />
                    <Route path="*" element={<Login />} />
                    {/* </Route> */}
                  </Routes>
                </div>
              </Grid>
            </AuthContextProvider>
          </BrowserRouter>
        </RegisterProvider>
      </Grid>
    </div>
  );
}

export default App;
