import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem } from "@mui/material";

const Navbar = () => {
  const user = localStorage.getItem("loogedIn_User");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleAvatar = () => {
    if (user) {
      return user?.substring(0, 2).toUpperCase();
    } else {
      return "";
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("loggedIn_User");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate('/Login');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set anchorEl to the clicked element
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu by setting anchorEl to null
  };

  return (
    <div className="logo">
      <Link to="/Login">
        <span className="c-black">AD</span>
        <span className="c-red">TECH</span>
      </Link>
      {/* {user && <div className='avatar'>{handleAvatar()} 

      </div>} */}

      {user && (
        <div className="avatar">
          <Avatar onClick={handleClick}>{handleAvatar()}</Avatar>
          <Menu
            id="avatar-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Sign out</MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Navbar;
