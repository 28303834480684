import { useEffect, useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  Badge,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import APIS from "../Pages/APIS";
import { formatDate } from "../helper/util";

const AudienceStatusDialog = ({ selectedAudience, closeAudience }) => {
  console.log(selectedAudience, closeAudience, "sfdsfsd");
  const [audienceHistory, setAudienceHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAudiencePushHistory();
  }, []);

  const getAudiencePushHistory = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const history = await APIS.getAudiencePushHistory(
        token,
        selectedAudience.id
      );

      setAudienceHistory(history.audiencePushHistory);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error("Fetching Audience Push History Error", error);
    }
  };

  const getColor = (status) => {
    if (status === "Completed") return "success";
    if (status === "Failed") return "error";
    return "warning";
  };

  return (
    <Dialog
      open={selectedAudience ? true : false}
      maxWidth={"lg"}
      onClose={closeAudience}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid display={"grid"} margin={3} rowGap={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {selectedAudience?.audienceName ?? ""}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeAudience}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Audience Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Pushed date</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Pushed by</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Size</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Channel</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircularProgress />
            ) : (
              audienceHistory?.map((history, index) => {
                return (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{history.audience_name}</TableCell>
                    <TableCell>
                      {formatDate(history?.pushed_date?.value)}
                    </TableCell>
                    <TableCell>{history.pushed_by}</TableCell>
                    <TableCell>{history.size}</TableCell>
                    <TableCell>{history.channel}</TableCell>
                    <TableCell>
                      <Badge color={getColor(history.status)} variant="dot" />
                      <span style={{ paddingLeft: 8 }}>{history.status}</span>
                    </TableCell>
                    <TableCell>{history.remarks || ""}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Grid>
    </Dialog>
  );
};

export default AudienceStatusDialog;
