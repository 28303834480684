import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Navbar from "../Components/navbar";
import { Navigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import APIS from "./APIS";
import { Snackbar } from "@mui/material";

class Login extends React.Component {
  constructor(props) {
    super(); // exception thrown here when not called

    // Initial state can be defined here
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      loggedIn: false, // Add a loggedIn state to track login status
      isButtonDisabled: false,
      loginErrorMessage: "",
    };
  }

  handleLogin = async () => {
    // Perform validation
    const { email, password } = this.state;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });
    }

    if (!password) {
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }

    // If both fields are filled, proceed with login logic
    if (email && password) {
      try {
        //setIsButtonDisabled(true); // Disable the button on click
        // setLoginStatus({
        //   success: null,
        //   error: null,
        // });

        this.isButtonDisabled = true;
        const formData = {
          email: this.state.email,
          password: this.state.password,
        };

        const response = await APIS.Login(formData);
        if (response.code == 200) {
          //setLoginStatus({ success: response.message, error: null });
          // Store the token in local storage
          localStorage.setItem("token", response.token);
          localStorage.setItem("loogedIn_User", response.data.email);
          localStorage.setItem("userId", response.data.id);
          this.setState({ loggedIn: true });
          //Navigate('/ProjectList', { state: response.data });
        } else {
          this.setState({
            loginErrorMessage: response.message,
          });
          // setLoginStatus({ success: null, error: response.message });
        }
      } catch (error) {
        //setLoginStatus({ success: null, error: error });
      } finally {
        //setIsButtonDisabled(false);
        this.isButtonDisabled = false;
      }
    }
  };

  /** render()- method is used to render HTML */
  render() {
    const {
      email,
      password,
      emailError,
      passwordError,
      showPassword,
      setShowPassword,
      loginErrorMessage,
    } = this.state;
    const handleClickShowPassword = () =>
      this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const token = localStorage.getItem("token");
    if (this.state.loggedIn || token) {
      // alert();
      return <Navigate to="/ChannelSetup" />;
    }

    return (
      <div className="login-card">
        <Navbar></Navbar>

        <h2>Log In</h2>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              label="Email"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              value={email}
              type="email"
              onChange={(e) => this.setState({ email: e.target.value })}
              error={emailError}
              helperText={emailError ? "Enter a valid email address" : ""}
              required
              placeholder="Enter Email"
            />

            <FormControl variant="standard" fullWidth>
              <label
                htmlFor="standard-adornment-password"
                className="pwd-label"
              >
                Password<span className="c-red">*</span>
              </label>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="Enter Password"
                onChange={(e) => this.setState({ password: e.target.value })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={passwordError}>
                {passwordError && "Password is required."}
              </FormHelperText>
            </FormControl>

            <div>
              <Link to="/ResetPassword" className="link">
                Forgot Password
              </Link>
            </div>
            <Button
              variant="contained"
              size="medium"
              onClick={this.handleLogin}
            >
              LOG IN
            </Button>
            <div className="center">
              <span className="font-md">New to this site?</span>
              <span>
                <Link className="c-red font-md pd-l-10" to="/registration">
                  Create an Account
                </Link>
              </span>
            </div>
          </div>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={3000}
          open={!!loginErrorMessage}
          onClose={() => this.setState({ loginErrorMessage: "" })}
          message={loginErrorMessage}
          key={"topright"}
        />
      </div>
    );
  }
}

export default Login;
