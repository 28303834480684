import React, { useState, createContext, useContext } from "react";

const initalState = {
  registerData: {},
};

const RegisterContext = createContext(initalState);

export const RegisterProvider = ({ children }) => {
  const [registerData, setRegisterData] = useState({});
  return (
    <RegisterContext.Provider
      value={{ registerData, setRegisterData}}
    >
      {/* {renderComponent()} */}
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegisterContext = () => useContext(RegisterContext);
