import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AudienceFooter from './AudienceFooter';
import APIS from './APIS';

const LocationCheckboxes = ({ locationObj,  selectedRegions, setSelectedRegions,
  selectedProvinces,setSelectedProvinces,
  selectedDistricts,setSelectedDistricts,
  provinceDistrictCount, setProvinceDistrictCount,
  regionProvinceCount, setRegionProvinceCount,
  handleNextTab }) => {
  // const [selectedRegions, setSelectedRegions] = useState([]);
  // const [selectedProvinces, setSelectedProvinces] = useState([]);
  // const [selectedDistricts, setSelectedDistricts] = useState([]);

  const [regionFilter, setRegionFilter] = useState('');
  const [provinceFilter, setProvinceFilter] = useState('');
  const [districtFilter, setDistrictFilter] = useState('');

  const [geoCount, setGeoCount] = useState(0);
  const [totalSelectionCount, setTotalSelectionCount] = useState(0);

  // const [provinceDistrictCount, setProvinceDistrictCount] = useState([]);
  // const [regionProvinceCount, setRegionProvinceCount] = useState([]);


  useEffect(() => {
    // Set initial state onload
    loadSelectedRegionsData();
  }, [selectedRegions]);

  const filteredRegions = locationObj.Location.filter(region =>
    region.Region.toLowerCase().includes(regionFilter.toLowerCase())
  );

  const calculateCount = () => {
    // let selectedCount = Object.keys(selectedOptions)
    //   .map((filterType) => selectedOptions[filterType]?.length || 0)
    //   .reduce((acc, count) => acc + count, 0);

    // let activeClassCount = Object.keys(activeClass)
    //   .map((filterType) => activeClass[filterType] ? 1 : 0)
    //   .reduce((acc, count) => acc + count, 0);

    // let totalSelectionCount = selectedCount;

    setTotalSelectionCount(1);
    console.log('Total Selection Count:', totalSelectionCount);
  };


  const handleCalculate = async () => {
    const filterData = {};
   

    // const finalObj = {geog: 
    // {

    // }};

    console.log(selectedProvinces);
    console.log(selectedDistricts);

    const provincefilters ={filters: selectedProvinces.map(prov => ({ value: prov }))} ;
    const disctrictfiter = {filters: selectedDistricts.map(dist => ({ value: dist }))};

    const finalObj = {
      'geog': {
        'resident_location_district_en_cat': disctrictfiter, 
        'resident_location_province_en_cat': provincefilters
      }
    }
   // console.log('fil dt:', finalObj);

    try {
      let response = await APIS.calculateCountGeographics(finalObj, localStorage.getItem('token'));
      console.log('response:' ,response);
      if (response.code == 200) {
        setGeoCount(response.count);
      } 
    } catch (error) {
      console.log('Error:' ,error);
    } 
  };

  const handleClearAll = () => {
  };

  const loadSelectedRegionsData = () => {
    locationObj.Location.forEach(region => {
      const regionName = region.Region;
      const provinceCount = region.Province.length;
      regionProvinceCount[regionName] = provinceCount;

      region.Province.forEach(province => {
        const provinceName = province.value;
        const districtCount = province.district.length;
        provinceDistrictCount[provinceName] = districtCount;
      });
    });
    setProvinceDistrictCount(provinceDistrictCount);
    setRegionProvinceCount(regionProvinceCount);
  };

  const handleRegionChange = (region) => {
    const newSelectedRegions = [...selectedRegions];
    const regionIndex = newSelectedRegions.indexOf(region.toLowerCase());

    if (regionIndex === -1) {
      newSelectedRegions.push(region.toLowerCase());
    } else {
      newSelectedRegions.splice(regionIndex, 1);
    }

    setSelectedRegions(newSelectedRegions);
  };

  const handleProvinceChange = (province) => {
    const newSelectedProvinces = [...selectedProvinces];
    const provinceIndex = newSelectedProvinces.indexOf(province);

    if (provinceIndex === -1) {
      newSelectedProvinces.push(province);
    } else {
      newSelectedProvinces.splice(provinceIndex, 1);
    }

    setSelectedProvinces(newSelectedProvinces);

    // Update districts when provinces change
    const selectedDistrictsArray = locationObj.Location
      .filter((region) => selectedRegions.includes(region.Region.toLowerCase()))
      .flatMap((region) =>
        region.Province
          .filter((prov) => newSelectedProvinces.includes(prov.value))
          .flatMap((prov) => prov.district.map((district) => district.value))
      );

    //setSelectedDistricts(selectedDistrictsArray);
  };


  const handleDistrictChange = (district) => {
    const newSelectedDistricts = [...selectedDistricts];
    const districtIndex = newSelectedDistricts.indexOf(district);

    if (districtIndex === -1) {
      newSelectedDistricts.push(district);
    } else {
      newSelectedDistricts.splice(districtIndex, 1);
    }

    setSelectedDistricts(newSelectedDistricts);
  };

  const handleSelectAllRegions = () => {
    const allRegions = locationObj.Location.map((region) => region.Region.toLowerCase());
    setSelectedRegions(selectedRegions.length === allRegions.length ? [] : allRegions);
  };

  const handleSelectAllProvinces = () => {
    const allProvinces = locationObj.Location
      .filter((region) => selectedRegions.includes(region.Region.toLowerCase()))
      .flatMap((region) => region.Province.map((province) => province.value));

    setSelectedProvinces(selectedProvinces.length === allProvinces.length ? [] : allProvinces);
  };

  const handleSelectAllDistricts = () => {
    const allDistricts = locationObj.Location
      .filter((region) => selectedRegions.includes(region.Region.toLowerCase()))
      .filter((region) => selectedProvinces.includes(region.Province[0]?.value))
      .flatMap((region) =>
        region.Province
          .filter((prov) => selectedProvinces.includes(prov.value))
          .flatMap((prov) => prov.district.map((district) => district.value))
      );

    setSelectedDistricts(selectedDistricts.length === allDistricts.length ? [] : allDistricts);
  };

  return (
    <div>
      <Grid container spacing={2} className='checklist-card'>
        <Grid item xs={4}>
          <span>Region</span><br /><br />
          
          <div>
            <input
            type="text"
            placeholder="Search Region"
            value={regionFilter}
            onChange={(e) => setRegionFilter(e.target.value)}
            className='filter-text'
          /></div><br></br>
          <div>
            <label>
              <input
                type="checkbox"
                value="selectAllRegions"
                checked={selectedRegions.length === locationObj.Location.length}
                onChange={handleSelectAllRegions}
              />
              <span class="checkmark"></span>
              <label>All</label>
            </label>
            <span class="checkmark"></span>
          </div>
          {filteredRegions.map((region) => (
            <div key={region.Region} className='mtb-4'>
              <label>
                <input
                  type="checkbox"
                  value={region.Region.toLowerCase()}
                  checked={selectedRegions.includes(region.Region.toLowerCase())}
                  onChange={() => handleRegionChange(region.Region)}
                  className={getRegionClassName(region.Region,selectedProvinces) == 'partial' ? 'partial' : ''}
                />

                <span class="checkmark"></span>
                <label> {region.Region.toLowerCase()}</label>
              </label>
            </div>
          ))}
        </Grid>

        {selectedRegions.length > 0 && (
          <Grid item xs={4}>
            <span>Province</span><br /><br />
            <div>
            <input
            type="text"
            placeholder="Search Province"
            value={provinceFilter}
            onChange={(e) => setProvinceFilter(e.target.value)}
            className='filter-text'
          /></div><br></br>

            {/* <div>
              <label>
                <input
                  type="checkbox"
                  value="selectAllProvinces"
                  checked={selectedProvinces.length === getSelectedProvinces().length}
                  onChange={handleSelectAllProvinces}
                />
                <span class="checkmark"></span>
                <label>All</label>
              </label>
            </div> */}
            {getSelectedProvinces().map((province) => (
              <div key={province} className='mtb-2'>
                <label>
                  <input
                    type="checkbox"
                    value={province}
                    checked={selectedProvinces.includes(province)}
                    onChange={() => handleProvinceChange(province)}
                    className={getProvinceClassName(province)}
                  />
                  <span class="checkmark"></span>
                  <label> {province} <span className='text-sub-title'> <ArrowForwardIcon /> {getRegionForProvince(province)}</span>
                  </label>
                </label>
              </div>
            ))}
          </Grid>
        )}

        {selectedProvinces.length > 0 && (
          <Grid item xs={4}>
            <span>District</span><br /><br />
            <div>
            <input
            type="text"
            placeholder="Search District"
            value={districtFilter}
            onChange={(e) => setDistrictFilter(e.target.value)}
            className='filter-text'
          /></div><br></br>

            {/* <div>
              <label>
                <input
                  type="checkbox"
                  value="selectAllDistricts"
                  checked={selectedDistricts.length === getSelectedDistricts().length}
                  onChange={handleSelectAllDistricts}
                />
                <span class="checkmark"></span>
                <label> All</label>
              </label>
            </div> */}
            {getSelectedDistricts().map((district) => (
              <div key={district} className='mtb-2'>
                <label>
                  <input
                    type="checkbox"
                    value={district}
                    checked={selectedDistricts.includes(district)}
                    onChange={() => handleDistrictChange(district)}
                  />

                  <span class="checkmark"></span>
                  <label>
                    {district} <span className='text-sub-title'> <ArrowForwardIcon /> {getProvinceForDistrict(district)}</span>
                  </label>
                </label>
              </div>
            ))}
          </Grid>
        )}
      </Grid>
      <div>
        <AudienceFooter count={geoCount} onCalculate={handleCalculate} totalSlectionCount={totalSelectionCount} onChildClick={handleClearAll} onNextClick={handleNextTab}/>
      </div>
    </div>
  );

  function getSelectedProvinces() {
    return locationObj.Location
      .filter((region) => selectedRegions.includes(region.Region.toLowerCase()))
      .flatMap((region) => region.Province.map((province) => province.value))
      .filter((province) =>
      province.toLowerCase().includes(provinceFilter.toLowerCase())
    );
  }

  function getSelectedDistricts() {
    const selectedDistrictsArray = locationObj.Location
      .filter((region) => selectedRegions.includes(region.Region.toLowerCase()))
      .flatMap((region) =>
        region.Province
          .filter((prov) => selectedProvinces.includes(prov.value))
          .flatMap((prov) => prov.district.map((district) => district.value))
      ).filter(district =>
        district.toLowerCase().includes(districtFilter.toLowerCase())
      );

    return selectedDistrictsArray || [];
  }

  function getRegionForProvince(province) {
    const region = locationObj.Location.find((region) =>
      region.Province.some((prov) => prov.value === province)
    );
    return region ? region.Region.toLowerCase() : '';
  }

  function getProvinceForDistrict(district) {
    const province = locationObj.Location
      .flatMap((region) => region.Province)
      .find((prov) => prov.district.some((dist) => dist.value === district));

    return province ? province.value : '';
  }

  function getRegionClassName(region, selectedProvinces) {
    console.log('selectedProvinces:',selectedProvinces)
    const countForRegion = regionProvinceCount[region];
console.log(countForRegion);
    const currentCount = findRegionCounts(selectedProvinces);
    if (countForRegion > currentCount[region]) {
      return 'partial';
    } else if (countForRegion == currentCount[region]) {
      return '';
    } else {
      return '';
    }
  }

  function findRegionCounts(province) {
    const regionCounts = {};

    selectedProvinces.forEach((province) => {
      const region = getRegionForProvince(province, locationObj);
      if (region) {
        regionCounts[region] = (regionCounts[region] || 0) + 1;
      }
    });

    return regionCounts;
  }

  function getProvinceClassName(province) {
    const countForProvince = provinceDistrictCount[province];
    const currentCount = findProvinceCounts(selectedDistricts);

    if (countForProvince > currentCount[province]) {
      return 'partial';
    } else if (countForProvince == currentCount[province]) {
      return '';
    } else {
      return '';
    }
  }

  function findProvinceCounts(districtsToFind) {
    const provinceCounts = {};

    locationObj.Location.forEach((region) => {
      region.Province.forEach((province) => {
        const provinceName = province.value;
        const matchingDistricts = districtsToFind.filter((district) =>
          province.district.some((dist) => dist.value === district)
        );

        if (matchingDistricts.length > 0) {
          provinceCounts[provinceName] = (provinceCounts[provinceName] || 0) + matchingDistricts.length;
        }
      });
    });

    return provinceCounts;
  }

};


export default LocationCheckboxes;
