import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from "../Components/MultiSelectDropdown";
import AudienceFooter from './AudienceFooter';
import APIS from './APIS';

const CommercialRelevant = ({ commRelevantData, commRelevantselectedFilters, setCommRelevantselectedFilters, handleNextTab }) => {
    const [selectedOptions, setSelectedOptions] = useState({});
    const [totalSelectionCount, setTotalSelectionCount] = useState(0);
    const [demoCount, setDemoCount] = useState(0);

    useEffect(() => {
        calculateCount();
    }, [selectedOptions]);

    const handleClearAll = () => {
        setSelectedOptions({});
    };



    const calculateCount = () => {
        const activeButtons = document.querySelectorAll('.form-btn.active');
        const activeCount = activeButtons.length;

        const combinedCount = Object.keys(selectedOptions)
            .map(filterType => selectedOptions[filterType]?.length || 0)
            .reduce((acc, count) => acc + count, 0);

        // alert(activeCount);
        // const currentCount = totalSelectionCount++;
        setTotalSelectionCount(combinedCount);
    };


    const handleOptionChange = (filterType, selectedValues, event, isBtn) => {
        setCommRelevantselectedFilters((prevOptions) => ({
            ...prevOptions,
            [filterType]: selectedValues.map((value) => value),
        }));

        if (isBtn) {
            const elements = document.querySelectorAll('.' + filterType);
            elements.forEach(element => {
                if (element !== event.currentTarget) {
                    element.classList.remove('active');
                }
            });

            if (event.currentTarget != undefined) {
                event.currentTarget.classList.toggle('active');
            }
        }
    };

    const handleCalculate = async () => {
        const filterData = {};
        // Iterate over the original data
        for (const [key, value] of Object.entries(commRelevantselectedFilters)) {
          // Create a new object with the desired structure
          filterData[key] = {
            filters: value.map(item => ({ value: item.value }))
          };
        }
    
        const finalObj = {comm_relevance: filterData};
    
       // console.log('fil dt:', finalObj);
    
        try {
          let response = await APIS.calculateCountCommercialRelevant(finalObj, localStorage.getItem('token'));
          console.log('response:' ,response);
          if (response.code == 200) {
            setDemoCount(response.count);
          } 
        } catch (error) {
          console.log('Error:' ,error);
        } 
      };

    const renderFilter = (filterType, options) => {
        return (
            <Grid item xs={6} key={filterType}>
                <label>{convertToCamelCase(filterType)}</label>
                <br />
                {filterType == 'android_box_mau' || filterType == 'tdg_customer' || filterType == 'foreigner' || filterType == 'trueid_mau' ? (
                    options.map((option) => (
                        <Button className={`form-btn ${filterType}`} key={option.value} variant="outlined" sx={{ mr: 0.5, width: '140px', height: 32 }}
                            onClick={(event) => handleOptionChange(filterType, [option.value], event, true)}>
                            {option.value}
                        </Button>
                    ))
                ) : (

                    <Select
                        onOptionChange={(selectedValues, event) => handleOptionChange(filterType, selectedValues, event, false)}
                        colourOptions={options.filter((filter) => filter.value !== null).map((filter) => ({
                            value: filter.value,
                            label: filter.value,
                            sublabel: convertCount(filter.count),
                        }))}
                        optionSelected={commRelevantselectedFilters[filterType]}
                    />
                )}


            </Grid>
        );
    };

    function convertToCamelCase(inputString) {
        return inputString
            .replace(/_/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()));
    }

    return (
        <div>
            <div className='sec-title'>Commercial Relevant</div>
            <Grid container spacing={2} className='content-container'>
                {Object.entries(commRelevantData).map(([filterType, filterData]) =>
                    renderFilter(filterType, filterData.filters)
                )}
            </Grid>
            <div>
        <AudienceFooter count={demoCount} onCalculate={handleCalculate} totalSlectionCount={totalSelectionCount} onChildClick={handleClearAll} onNextClick={handleNextTab}/>
      </div>
        </div>
    );

    function convertCount(count) {
        let convertedCount = count;
        if (count >= 1000000) {
            convertedCount = (count / 1000000).toFixed(2) + "M";
        } else if (count >= 1000) {
            convertedCount = (count / 1000).toFixed(2) + "K";
        } else {
            convertedCount = count;
        }
        return convertedCount;
    }
};

export default CommercialRelevant;
