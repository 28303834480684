import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from "react-select";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AudienceFooter from './AudienceFooter';
import Button from '@mui/material/Button';
import APIS from './APIS';

let radiusArr = ['1', '2', '3'];

const TabLocationTraffic = ({ handleNextTab,locationTrafficData, setLocationTrafficData }) => {
  const [home_lat, setLat] = useState('');
  const [home_long, setLong] = useState('');
  const [radius, setRadius] = useState('');
  const [locTrafficCount, setLocTrafficCount] = useState(0);
  //const [data, setData] = useState([]);

  const handleLatChange = (event) => {
    setLat(event.target.value);
  };

  const handleLongChange = (event) => {
    setLong(event.target.value);
  };

  // const handleRadiusChange = (selectedOption) => {
  //   setRadius(selectedOption);
  // };

  const handleRadiusChange = (event) => {
    setRadius(event.target.value);
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    if (home_lat && home_long && radius) {
      setLocationTrafficData([...locationTrafficData, { home_lat, home_long, radius }]);
      setLat('');
      setLong('');
      setRadius('');
    }

    console.log('dt:', locationTrafficData);
  };


  const handleCalculate = async () => {
    console.log('fil dt:', locationTrafficData);

    const finalObj = {'locationTraffic': locationTrafficData}
    try {
      let response = await APIS.calculateCountLocationTraffic(finalObj, localStorage.getItem('token'));
      console.log('response:' ,response);
      if (response.code == 200) {
        setLocTrafficCount(response.count);
      } 
    } catch (error) {
      console.log('Error:' ,error);
    } 
  };

  const handleDelete = (index) => {
    const updatedData = [...locationTrafficData];
    updatedData.splice(index, 1);
    setLocationTrafficData(updatedData);
  };

  return (
    <Grid container spacing={2} className='content-container'>
      <Grid item xs={3}>
        <div className='content-card'>
          <div>
            <TextField
              label="Lat"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              type='text'
              required
              placeholder='Enter Lat e.g 123213'
              sx={{ width: '100%' }}
              value={home_lat}
              onChange={handleLatChange}
            />
          </div>
          <div><br />
            <TextField
              label="Long"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              type='text'
              required
              placeholder='Enter Long e.g 123213'
              sx={{ width: '100%' }}
              value={home_long}
              onChange={handleLongChange}
            />
          </div><br />
          <div>
            {/* <label>Radius</label> */}
            {/* <Select options={radiusArr} isMulti value={radius} onChange={handleRadiusChange} /> */}
            <TextField
              label="Radius"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              type='text'
              required
              placeholder='Enter Radius'
              sx={{ width: '100%' }}
              value={radius}
              onChange={handleRadiusChange}
            />
          </div>
          <br></br>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" size="small" onClick={(event) => handleAddRow(event)}>
              ADD
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={9}>
        <div className='content-card3'>
          <div className='sec-sm-title'>Location Traffic ({locationTrafficData.length})</div>
          <Table>
            <TableHead>
              {/* <TableRow>
                <TableCell>Latitude</TableCell>
                <TableCell>Longitude</TableCell>
                <TableCell>Radius</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow> */}
            </TableHead>
            <TableBody>
              {locationTrafficData.map((rowData, index) => (
                <TableRow key={index}>
                  <TableCell>Lat: <b>{rowData.home_lat}</b></TableCell>
                  <TableCell>Long: <b>{rowData.home_long}</b></TableCell>
                  <TableCell>Radius: <b>{rowData.radius}</b></TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(index)}>
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <AudienceFooter onNextClick={handleNextTab} count={locTrafficCount} onCalculate={handleCalculate}/>
      </Grid>
    </Grid>
  );
};

export default TabLocationTraffic;
