import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AudienceFooter from './AudienceFooter';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import APIS from './APIS';

const TabWebsiteTraffic = ({ handleNextTab, websiteTrafficData, setWebsiteTrafficData }) => {
  const [webUrl, setWebUrl] = useState('');
  const [matchCriteria, setMatchCriteria] = useState('');
  const [interestValue, setInterestValue] = useState('high');
  const [selectedPeriod, setSelectedPeriod] = useState('1'); // State for selected period value
  const [websiteTrafficCount, setWebsiteTrafficCount] = useState(0);


  const handleCalculate = async () => {
    console.log('fil dt:', websiteTrafficData);

    // Constructing finalObj
    const filters = websiteTrafficData.map(item => ({
      webUrl: item.webUrl,
      matchCriteria: item.matchCriteria,
      interestValue: item.interestValue
    }));

    const finalObj = {
      websiteTraffic: {
        period: parseInt(selectedPeriod), // Convert selectedPeriod to integer if necessary
        filters: filters
      }
    };


    try {
      let response = await APIS.calculateCountWebsiteTraffic(finalObj, localStorage.getItem('token'));
      console.log('response:' ,response);
      if (response.code == 200) {
        setWebsiteTrafficCount(response.count);
      } 
    } catch (error) {
      console.log('Error:' ,error);
    } 
  };

  const handleURLChange = (event) => {
    setWebUrl(event.target.value);
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    if (matchCriteria && webUrl) {
      setWebsiteTrafficData([...websiteTrafficData, { webUrl, matchCriteria, interestValue }]); // Include selectedPeriod in the data array
      setWebUrl('');
      setMatchCriteria('');
      setInterestValue('high');
     // setSelectedPeriod('1'); // Reset selectedPeriod after adding
    }
  };

  const handleDelete = (index) => {
    const updatedData = [...websiteTrafficData];
    updatedData.splice(index, 1);
    setWebsiteTrafficData(updatedData);
  };

  const handleQueryChange = (event) => {
    setMatchCriteria(event.target.value);
  };

  const handleInterestValueChange = (event) => {
    setInterestValue(event.target.value);
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <Grid container spacing={2} className='content-container'>
      <Grid item xs={3}>
        <div className='content-card'>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <label style={{ fontSize: 12, marginRight: 10 }}><b>Period:</b></label>
            <select style={{ fontSize: 14 }} value={selectedPeriod} onChange={handlePeriodChange}>
              <option value="1">1 month</option>
              <option value="2">2 month</option>
              <option value="3">3 month</option>
            </select>
          </div><br></br>

          <div>
            <TextField
              label="Web URL"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              type='text'
              required
              placeholder='Enter URL and click ‘ADD’ to add link'
              sx={{ width: '100%' }}
              value={webUrl}
              onChange={handleURLChange}
            />
          </div>
          <div>
            <RadioGroup aria-label="options" name="query" value={matchCriteria} onChange={handleQueryChange}>
              <FormControlLabel value="exact" control={<Radio />} label="EXACT" />
              <FormControlLabel value="contains" control={<Radio />} label="CONTAINS" />
            </RadioGroup>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" size="small" onClick={(event) => handleAddRow(event)}>
              ADD
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={9}>
        <div className='content-card3'>
          <div className='sec-sm-title'>Website Traffic ({setWebsiteTrafficData.length})</div>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              {websiteTrafficData.map((rowData, index) => (
                <TableRow key={index}>
                  <TableCell><b>{rowData.webUrl}</b></TableCell>
                  <TableCell>Match criteria: <b>{rowData.matchCriteria}</b></TableCell>
                  <TableCell>Interest value: <b>{rowData.interestValue}</b></TableCell>
                  {/* <TableCell>Period: <b>{rowData.selectedPeriod}</b></TableCell> */}
                  <TableCell>

                    <select style={{ fontSize: 12 }} name='interestValue' value={interestValue} onChange={handleInterestValueChange}>
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                      <option value="all">All</option>
                    </select>

                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(index)}>
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <AudienceFooter onNextClick={handleNextTab} count={websiteTrafficCount} onCalculate={handleCalculate} />
      </Grid>
    </Grid>
  );
};

export default TabWebsiteTraffic;
