import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from "../Components/MultiSelectDropdown";
import AudienceFooter from './AudienceFooter';
import APIS from './APIS';

const Demographics = ({ demographicsData, demographicsselectedFilters, setDemographicsselectedFilters,handleNextTab  }) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [activeClass, setActiveClass] = useState({});
  const [totalSelectionCount, setTotalSelectionCount] = useState(0);
  const [demoCount, setDemoCount] = useState(0);
  
  useEffect(() => {
    calculateCount();
    //fetchDemographicData();
  }, [selectedOptions, activeClass]);

  const handleClearAll = () => {
    setDemographicsselectedFilters({});
    setActiveClass({});
  };



  const handleOptionChange = (filterType, selectedValues, event, isBtn) => {
    setDemographicsselectedFilters((prevOptions) => ({
      ...prevOptions,
      [filterType]: selectedValues.map((value) => value),
    }));

    console.log(demographicsselectedFilters);

    //     setActiveClass((prevActiveClass) => ({
    //       ...prevActiveClass,
    //       [filterType]: !prevActiveClass[filterType],
    //     }));

    if (isBtn) {
      if (event.currentTarget != undefined) {
        event.currentTarget.classList.toggle('active');
      }
    }

  };

  const calculateCount = () => {
    let selectedCount = Object.keys(demographicsselectedFilters)
      .map((filterType) => demographicsselectedFilters[filterType]?.length || 0)
      .reduce((acc, count) => acc + count, 0);

    let activeClassCount = Object.keys(activeClass)
      .map((filterType) => activeClass[filterType] ? 1 : 0)
      .reduce((acc, count) => acc + count, 0);

    let totalSelectionCount = selectedCount;

    setTotalSelectionCount(totalSelectionCount);
    console.log('Total Selection Count:', totalSelectionCount);
  };

  const handleCalculate = async () => {
    const filterData = {};
    // Iterate over the original data
    for (const [key, value] of Object.entries(demographicsselectedFilters)) {
      // Create a new object with the desired structure
      filterData[key] = {
        filters: value.map(item => ({ value: item.value }))
      };
    }

    const finalObj = {demo: filterData};

    console.log('fil dt:', finalObj);

    try {
      let response = await APIS.calculateCountDemographics(finalObj, localStorage.getItem('token'));
      console.log('response:' ,response);
      if (response.code == 200) {
        setDemoCount(response.count);
      } 
    } catch (error) {
      console.log('Error:' ,error);
    } 
  };

  const renderFilter = (filterType, options) => {
    return (
      <Grid item xs={6}>
        <label>{filterType}</label>
        <br />
        {filterType === 'Gender' ? (
          options.map((option) => (
            <Button
              className={`form-btn ${activeClass[filterType] ? 'active' : ''}`}
              key={option.value}
              variant="outlined"
              sx={{ mr: 0.5, width: '140px' }}
              onClick={(event) => handleOptionChange(filterType, [option.value], event, true)}
            >
              {option.value}
            </Button>
          ))
        ) : (
          <Select
            onOptionChange={(selectedValues, event) => handleOptionChange(filterType, selectedValues, event, false)}
            colourOptions={options.filter((filter) => filter.value !== null).map((filter) => ({
              value: filter.value,
              label: filter.value,
              sublabel: convertCount(filter.count)
            }))}
            optionSelected={demographicsselectedFilters[filterType]}
          />
        )}
      </Grid>
    );
  };

  return (
    <div>
      <div className='sec-title'>Demographics</div>


      <Grid container spacing={2} className='content-container'>
        {Object.entries(demographicsData).map(([filterType, filterData]) => (
          renderFilter(filterType, filterData.filters)
        ))}
      </Grid>
      <div>
        <AudienceFooter count={demoCount} onCalculate={handleCalculate} totalSlectionCount={totalSelectionCount} onChildClick={handleClearAll} onNextClick={handleNextTab}/>
      </div>
    </div>
  );

  function convertCount(count) {
    let convertedCount = count;
    if (count >= 1000000) {
      convertedCount = (count / 1000000).toFixed(2) + "M";
    } else if (count >= 1000) {
      convertedCount = (count / 1000).toFixed(2) + "K";
    } else {
      convertedCount = count;
    }
    return convertedCount;
  }
}

export default Demographics;
