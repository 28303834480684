import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  MenuItem,
  Select,
  Dialog,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
  Snackbar,
} from "@mui/material";
import Sidebar from "../Components/sidebar";
import Navbar from "../Components/navbar";
import { ArrowBack, Close, Error } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import CustomSwitch from "../Components/CustomSwitch";
import APIS from "./APIS";
import SuccessLogo from "../Assets/success.svg";
import ChannelLogoLable from "../Components/ChannelLogoLable";

const AudiencePerformPush = () => {
  const { id } = useParams();
  const [checkedGoogle, setCheckGoogle] = useState(false);
  const [checkedMeta, setcheckMeta] = useState(false);
  const [checkTiktok, setCheckTiktok] = useState(false);
  const [metaBusinessAccounts, setmetaBusinessAccounts] = useState([]);
  const [googleAdsAccounts, setGoogleAdsAccount] = useState([]);
  const [tiktokAdsAccounts, setTikTokAdsAccounts] = useState([]);
  const [selectedGoogle, setSelectedGoogle] = useState("");
  const [selectedMeta, setSelectedMeta] = useState("");
  const [selectMetaAdsAccount, setSelectMetaAdsAccount] = useState("");
  const [selectTiktokAdsAccount, setSelectTiktokAdsAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const [successDialog, showSuccessDialog] = useState(false);
  const [failDialog, showFailDialog] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState("");
  const nevigate = useNavigate();

  useEffect(() => {
    getGoogleAdsAccounts();
    getMetaAccounts();
    getTikTokAds();
  }, []);

  const getMetaAccounts = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await APIS.getMetaAccounts(token);
      setmetaBusinessAccounts(response.accounts ?? []);
      setLoading(false);
    } catch (error) {
      setError("Error Fetching MetaAds Account");
      setLoading(false);
    }
  };

  const getMetaBusinessAccounts = () => {
    const business_manager_ids = metaBusinessAccounts.map(
      (obj) => obj.business_manager_id
    );

    return business_manager_ids;
  };

  const getMetaAdsAccounts = () => {
    if (selectedMeta) {
      const filterArray = metaBusinessAccounts.filter(
        (account) => account["business_manager_id"] == selectedMeta
      );
      return filterArray[0]["account_ids"];
    }
    return [];
  };

  const getGoogleAdsAccounts = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await APIS.getGoogleAdsAccounts(token);
      setGoogleAdsAccount(response.customerIds);
      setLoading(false);
    } catch (error) {
      setError("Error Fetching Google Ads Account");
      setLoading(false);
    }
  };

  const getTikTokAds = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await APIS.getTikTokAdsAccounts(token);
      setTikTokAdsAccounts(response.data ?? []);
      setLoading(false);
    } catch (error) {
      console.error("Error Fetching  Tiktoks Account", error);
      setLoading(false);
    }
  };

  const performAudiencePush = async () => {
    try {
      if (checkedGoogle && !selectedGoogle) {
        setError("Select Google Ads Account ");
        return;
      }

      if (checkedMeta && !selectMetaAdsAccount) {
        setError("Select Meata Ads Account");
        return;
      }

      if (checkTiktok && !selectTiktokAdsAccount) {
        setError("Select Tiktok Ads Account");
        return;
      }

      let promises = [];
      showSuccessDialog(true);
      // setLoading(true);
      if (checkedGoogle) {
        const promise = pushGoogleAudience();
        promises = [...promises, promise];
      }
      if (checkedMeta) {
        const promise = pushMetaAudience();
        promises = [...promises, promise];
      }

      if (checkTiktok) {
        const promise = pushTiktokAudience();
        promises = [...promises, promise];
      }
      const resposnes = await Promise.all(promises);
      // const error = resposnes.filter((response) => response.error);
      // if (error.length > 0) {
      //   setError(error[0]?.message ?? "One or more API requests failed");
      // } else {
      //   showSuccessDialog(true);
      // }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("One or more API requests failed", error);
    }
  };

  const pushGoogleAudience = async () => {
    try {
      const token = localStorage.getItem("token");

      const data = {
        customerId: selectedGoogle,
        audienceId: id,
        campaignCreation: false,
      };
      return await APIS.pushAudienceGoogle(token, data);
    } catch (error) {
      throw new Error("Google Audience Push Failed!", error);
    }
  };

  const pushMetaAudience = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = {
        businessManagerId: selectedMeta,
        adsAccountId: selectMetaAdsAccount,
        audienceId: id,
        campaignCreation: false,
      };
      return await APIS.pushAudienceMeta(token, data);
    } catch (error) {
      throw new Error("Meta Audience Push Failed!", error);
    }
  };

  const pushTiktokAudience = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = {
        adsId: selectTiktokAdsAccount,
        audienceId: id,
      };
      return await APIS.pushAudienceTiktok(token, data);
    } catch (error) {
      throw new Error("Tiktok Audience Push Failed!", error);
    }
  };

  const handleGoAudienceList = () => {
    nevigate("/AudienceList");
  };

  const handleCloseSanckBar = () => {
    setError("");
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const isButtonDisabled = () => {
    if (loading) return loading;
    if (!checkedGoogle && !checkedMeta && !checkTiktok) {
      return true;
    }
    return false;
  };

  const getConfirmDialogContent = () => {
    let adsArray = [];
    let channelArray = [];
    let ads = "";
    let channels = "";
    if (checkedGoogle) {
      channelArray.push("Google");
      adsArray.push(`Google (${selectedGoogle})`);
    }

    if (checkedMeta) {
      channelArray.push("Meta");
      adsArray.push(`Meta (${selectMetaAdsAccount})`);
    }

    if (checkTiktok) {
      channelArray.push("Tiktok");
      adsArray.push(`Tiktok (${selectTiktokAdsAccount})`);
    }

    if (channelArray.length === 1) {
      ads = adsArray[0];
      channels = channelArray[0];
    } else {
      const lastAds = adsArray.pop();
      const lastChannel = channelArray.pop();
      ads = `${adsArray.join(", ")} and ${lastAds}`;
      channels = `${channelArray.join(", ")} and ${lastChannel}`;
    }

    return `You are about to push the audience segment to ‘${ads}’. Once pushed, this segment will be used in your ${channels} Ads campaign. Do you want to proceed?`;
  };

  const ConfirmDialog = () => (
    <Dialog
      open={confirm}
      onClose={() => setConfirm(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <Grid
        display={"grid"}
        padding={"24px"}
        justifyContent={"center"}
        rowGap={2}
      >
        <Grid display={"grid"} justifyContent={"center"}>
          <img src={SuccessLogo} />
        </Grid>

        <Typography
          display={"grid"}
          variantMapping="h2"
          justifyContent={"center"}
          fontWeight={"bold"}
        >
          Do you want to confirm push Audience?
        </Typography>
        <Grid display={"grid"} justifyContent={"center"}>
          {getConfirmDialogContent()}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setConfirm(false)}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setConfirm(false);
                performAudiencePush();
              }}
            >
              CONFIRM
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );

  const SuccessDialog = () => (
    <Dialog
      open={successDialog}
      onClose={() => showSuccessDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        display={"grid"}
        padding={"24px"}
        justifyContent={"center"}
        rowGap={2}
      >
        <Grid display={"grid"} justifyContent={"center"}>
          <img src={SuccessLogo} />
        </Grid>

        <Typography
          display={"grid"}
          variantMapping="h2"
          justifyContent={"center"}
          fontWeight={"bold"}
        >
          Successfully pushed audience!
        </Typography>
        <Grid display={"grid"} justifyContent={"center"}>
          Your audience is pushed to Ads platform
        </Grid>
        <Button fullWidth variant="contained" onClick={handleGoAudienceList}>
          GO TO AUDIENCE LIST
        </Button>
      </Grid>
    </Dialog>
  );

  const FailDialog = () => (
    <Dialog
      open={failDialog}
      onClose={() => showFailDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        display={"grid"}
        padding={1}
        marginTop={2}
        justifyContent={"center"}
        rowGap={2}
      >
        <IconButton
          aria-label="close"
          onClick={() => showFailDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <Grid display={"grid"} justifyContent={"center"}>
          <Error color="warning" fontSize="large" />
        </Grid>

        <Typography
          display={"grid"}
          variantMapping="h3"
          justifyContent={"center"}
          fontWeight={"bold"}
        >
          QUOTA EXCEED
        </Typography>
        <Grid display={"grid"} alignItems={"center"}>
          It seems you've reached the limit of available quota to perform
          audience actions. Please contact the admin for assistance.
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => showFailDialog(false)}
            >
              LATER
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth>
              CONTACT SALES
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );

  return (
    <div className="inner-wrapper">
      <Grid container>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={1}>
          <Sidebar />
        </Grid>
        <Grid item xs={11} padding={2}>
          <div className="content-card ">
            <h2>Perform Audience Push</h2>
            <div className="sub-title">To set audience segment</div>
            <div className="back-text" onClick={handleGoAudienceList}>
              <ArrowBack />
              <span>Back to Audience List</span>
            </div>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid marginTop={5}>
                <h4>Choose Your Channel</h4>
                <Grid>Description</Grid>
                <Grid className="content-card" marginTop={3}>
                  <Grid padding={3}>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <CustomSwitch
                        checked={checkedGoogle}
                        disabled={!googleAdsAccounts || googleAdsAccounts?.length == 0}
                        onClick={() => setCheckGoogle(!checkedGoogle)}
                      />
                      <Grid marginX={1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0 0 48 48"
                        >
                          <path
                            fill="#FFC107"
                            d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                          ></path>
                          <path
                            fill="#FF3D00"
                            d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                          ></path>
                          <path
                            fill="#4CAF50"
                            d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                          ></path>
                          <path
                            fill="#1976D2"
                            d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                          ></path>
                        </svg>
                      </Grid>
                      <Box component={"div"}>Google</Box>
                    </Grid>
                    <Grid fontSize={14}>Choose A Google Ads Account</Grid>
                    <Grid mt={1}>
                      <Select
                        size={"small"}
                        value={selectedGoogle}
                        disabled={!checkedGoogle}
                        fullWidth
                        onChange={(data) => {
                          setSelectedGoogle(data.target.value);
                        }}
                      >
                        {googleAdsAccounts?.map((acccount) => (
                          <MenuItem key={acccount} value={acccount}>
                            {acccount}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className="content-card" marginTop={3}>
                  <Grid padding={3}>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <CustomSwitch
                        checked={checkedMeta}
                        disabled={!metaBusinessAccounts}
                        onClick={() => setcheckMeta(!checkedMeta)}
                      />
                      <Grid marginX={1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0 0 48 48"
                        >
                          <path
                            fill="#0081fb"
                            d="M47,29.36l-2.193,1.663L42.62,29.5c0-0.16,0-0.33-0.01-0.5c0-0.16,0-0.33-0.01-0.5	c-0.14-3.94-1.14-8.16-3.14-11.25c-1.54-2.37-3.51-3.5-5.71-3.5c-2.31,0-4.19,1.38-6.27,4.38c-0.06,0.09-0.13,0.18-0.19,0.28	c-0.04,0.05-0.07,0.1-0.11,0.16c-0.1,0.15-0.2,0.3-0.3,0.46c-0.9,1.4-1.84,3.03-2.86,4.83c-0.09,0.17-0.19,0.34-0.28,0.51	c-0.03,0.04-0.06,0.09-0.08,0.13l-0.21,0.37l-1.24,2.19c-2.91,5.15-3.65,6.33-5.1,8.26C14.56,38.71,12.38,40,9.51,40	c-3.4,0-5.56-1.47-6.89-3.69C1.53,34.51,1,32.14,1,29.44l4.97,0.17c0,1.76,0.38,3.1,0.89,3.92C7.52,34.59,8.49,35,9.5,35	c1.29,0,2.49-0.27,4.77-3.43c1.83-2.53,3.99-6.07,5.44-8.3l1.37-2.09l0.29-0.46l0.3-0.45l0.5-0.77c0.76-1.16,1.58-2.39,2.46-3.57	c0.1-0.14,0.2-0.28,0.31-0.42c0.1-0.14,0.21-0.28,0.31-0.41c0.9-1.15,1.85-2.22,2.87-3.1c1.85-1.61,3.84-2.5,5.85-2.5	c3.37,0,6.58,1.95,9.04,5.61c2.51,3.74,3.82,8.4,3.97,13.25c0.01,0.16,0.01,0.33,0.01,0.5C47,29.03,47,29.19,47,29.36z"
                          ></path>
                          <linearGradient
                            id="wSMw7pqi7WIWHewz2_TZXa_PvvcWRWxRKSR_gr1"
                            x1="42.304"
                            x2="13.533"
                            y1="24.75"
                            y2="24.75"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stop-color="#0081fb"></stop>
                            <stop offset=".995" stop-color="#0064e1"></stop>
                          </linearGradient>
                          <path
                            fill="url(#wSMw7pqi7WIWHewz2_TZXa_PvvcWRWxRKSR_gr1)"
                            d="M4.918,15.456	C7.195,11.951,10.483,9.5,14.253,9.5c2.184,0,4.354,0.645,6.621,2.493c2.479,2.02,5.122,5.346,8.419,10.828l1.182,1.967	c2.854,4.746,4.477,7.187,5.428,8.339C37.125,34.606,37.888,35,39,35c2.82,0,3.617-2.54,3.617-5.501L47,29.362	c0,3.095-0.611,5.369-1.651,7.165C44.345,38.264,42.387,40,39.093,40c-2.048,0-3.862-0.444-5.868-2.333	c-1.542-1.45-3.345-4.026-4.732-6.341l-4.126-6.879c-2.07-3.452-3.969-6.027-5.068-7.192c-1.182-1.254-2.642-2.754-5.067-2.754	c-1.963,0-3.689,1.362-5.084,3.465L4.918,15.456z"
                          ></path>
                          <linearGradient
                            id="wSMw7pqi7WIWHewz2_TZXb_PvvcWRWxRKSR_gr2"
                            x1="7.635"
                            x2="7.635"
                            y1="32.87"
                            y2="13.012"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stop-color="#0081fb"></stop>
                            <stop offset=".995" stop-color="#0064e1"></stop>
                          </linearGradient>
                          <path
                            fill="url(#wSMw7pqi7WIWHewz2_TZXb_PvvcWRWxRKSR_gr2)"
                            d="M14.25,14.5	c-1.959,0-3.683,1.362-5.075,3.465C7.206,20.937,6,25.363,6,29.614c0,1.753-0.003,3.072,0.5,3.886l-3.84,2.813	C1.574,34.507,1,32.2,1,29.5c0-4.91,1.355-10.091,3.918-14.044C7.192,11.951,10.507,9.5,14.27,9.5L14.25,14.5z"
                          ></path>
                          <path
                            d="M21.67,20.27l-0.3,0.45l-0.29,0.46c0.71,1.03,1.52,2.27,2.37,3.69l0.21-0.37c0.02-0.04,0.05-0.09,0.08-0.13 c0.09-0.17,0.19-0.34,0.28-0.51C23.19,22.5,22.39,21.29,21.67,20.27z M24.94,15.51c-0.11,0.14-0.21,0.28-0.31,0.42 c0.73,0.91,1.47,1.94,2.25,3.1c0.1-0.16,0.2-0.31,0.3-0.46c0.04-0.06,0.07-0.11,0.11-0.16c0.06-0.1,0.13-0.19,0.19-0.28 c-0.76-1.12-1.5-2.13-2.23-3.03C25.15,15.23,25.04,15.37,24.94,15.51z"
                            opacity=".05"
                          ></path>
                          <path
                            d="M21.67,20.27l-0.3,0.45c0.71,1.02,1.51,2.24,2.37,3.65c0.09-0.17,0.19-0.34,0.28-0.51C23.19,22.5,22.39,21.29,21.67,20.27 z M24.63,15.93c0.73,0.91,1.47,1.94,2.25,3.1c0.1-0.16,0.2-0.31,0.3-0.46c-0.77-1.14-1.52-2.16-2.24-3.06 C24.83,15.65,24.73,15.79,24.63,15.93z"
                            opacity=".07"
                          ></path>
                        </svg>
                      </Grid>
                      <Box component={"div"}>Meta</Box>
                    </Grid>
                    <Grid fontSize={14}>
                      Choose A Meta Business Manager Account
                    </Grid>
                    <Grid mt={1}>
                      <TextField
                        select
                        value={selectedMeta}
                        disabled={!checkedMeta}
                        size={"small"}
                        fullWidth
                        variant="outlined"
                        onChange={(data) => {
                          setSelectedMeta(data.target.value);
                        }}
                      >
                        {getMetaBusinessAccounts()?.map((acccount) => (
                          <MenuItem key={acccount} value={acccount}>
                            {acccount}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid fontSize={14} mt={1}>
                      Choose A Meta Ads Account
                    </Grid>
                    <Grid mt={1}>
                      <TextField
                        select
                        value={selectMetaAdsAccount}
                        disabled={!checkedMeta}
                        size={"small"}
                        fullWidth
                        onChange={(data) => {
                          setSelectMetaAdsAccount(data.target.value);
                        }}
                      >
                        {getMetaAdsAccounts()?.map((acccount) => (
                          <MenuItem
                            key={acccount.ads_account_id}
                            value={acccount.ads_account_id}
                          >
                            {acccount.ads_account_id}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className="content-card" marginTop={3}>
                  <Grid padding={3}>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <CustomSwitch
                        checked={checkTiktok}
                        disabled={!tiktokAdsAccounts?.length}
                        onClick={() => setCheckTiktok(!checkTiktok)}
                      />
                      <Grid marginX={1}>
                        <ChannelLogoLable channelName={"Tiktok"} />
                      </Grid>
                    </Grid>
                    <Grid fontSize={14}>Choose A Tiktok Ads Account</Grid>
                    <Grid mt={1}>
                      <Select
                        size={"small"}
                        value={selectTiktokAdsAccount}
                        disabled={!checkTiktok}
                        fullWidth
                        onChange={(data) => {
                          setSelectTiktokAdsAccount(data.target.value);
                        }}
                      >
                        {tiktokAdsAccounts?.map((acccount) => (
                          <MenuItem
                            key={acccount.advertiser_id}
                            value={acccount.advertiser_id}
                          >
                            {acccount.advertiser_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
          <Grid display={"flex"} marginTop={2} gap={2} justifyContent={"end"}>
            <Button variant="outlined" onClick={handleGoAudienceList}>
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => setConfirm(true)}
              disabled={isButtonDisabled()}
            >
              Perform Audience Push
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <SuccessDialog />
      <ConfirmDialog />
      <FailDialog />
      <Snackbar
        open={error ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={error}
        action={action}
      />
    </div>
  );
};

export default AudiencePerformPush;
