import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import Navbar from '../Components/navbar';
import Grid from '@mui/material/Grid';
import Sidebar from '../Components/sidebar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GetData from '../ServiceAPI/getData';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import APIS from './APIS'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRef } from "react";
import DialogConfirmation from '../Components/DialogInfo';
import MultiDelDialogConfirmation from '../Components/DialogInfo';
import DialogProjCreateSuccess from '../Components/DialogInfo';

const ProjectList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [openMultiDeleteConfirmationDialog, setOpenMultiDeleteConfirmationDialog] = useState(false);
  const [openProjectCreateSuccessDialog, setOpenProjectCreateSuccessDialog] = useState(false);

  

  const [projectOptopenDialog, setProjectOptopenDialog] = useState(false);
  const itemWithIdRef = useRef(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [isProjectEdit, setIsProjectEdit] = useState(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false); // State variable to track button click


  const [createProjStatus, setCreateProjStatus] = useState({
    success: null,
    error: null,
  });

  const [delProjStatus, setDelProjStatus] = useState({
    success: null,
    error: null,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, project) => {
    setAnchorEl(event.currentTarget);
    setProjectName(project.project_name);
    setProjectId(project.id);
    setIsProjectEdit(true);
    console.log(project);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  

  useEffect(() => {
    // Fetch data from API endpoint
    fetchProjects();
  }, []);

  const deleteProject = async (isMultiple) => {
    try {
      let projectData = {
        "idArr": isMultiple ? checkedCheckboxes : [projectId]
      }

      const response = await APIS.DeleteProjectDetail(projectData, localStorage.getItem('token'));

      if (response.status == 'success') {
        setDelProjStatus({ success: response.message, error: null });
        fetchProjects();
      } else {
        setDelProjStatus({ success: null, error: response.message });
      }
    } catch (error) {
      setDelProjStatus({ success: null, error: error });
    } 
  }

  const handleCreateProject = async () => {
    // Perform project creation logic here, for example:
    console.log('Creating project with name:', projectName);
    setIsButtonClicked(true); // Disable the button when clicked

    let projectData = {
      "projectName": projectName
    }

    try {
      let response = {};
      if (isProjectEdit) {
        projectData['id'] = projectId;
        response = await APIS.RenameProjectDetail(projectData, localStorage.getItem('token'));
      } else {
        response = await APIS.CreateProject(projectData, localStorage.getItem('token'));
      }

      if (response.code == 200) {
       // setProjects(response.data);
      // fetchProjects();
        setCreateProjStatus({ success: response.message, error: null });
        setOpenDialog(false);
        setOpenProjectCreateSuccessDialog(true);
        fetchProjects();
      } else {
        setCreateProjStatus({ success: null, error: response.message });
      }
    } catch (error) {
      setCreateProjStatus({ success: null, error: error });
    } finally {
      setIsButtonClicked(false); // Re-enable the button after the operation is complete
    }
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    // Get the value and checked status of the checkbox
    const { value, checked } = event.target;

    // If checkbox is checked, add its ID to the array, otherwise remove it
    if (checked) {
      setCheckedCheckboxes(prevState => [...prevState, value]);
    } else {
      setCheckedCheckboxes(prevState => prevState.filter(id => id !== value));
    }
  };

  const filteredProjects =  projects.filter(project => {
    const created_by = project.created_by || '';
    const projectName = project.project_name || '';

    return created_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
      projectName.toLowerCase().includes(searchTerm.toLowerCase())
  }).filter(project => {
    const projectDate = new Date(project.created_at);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    return (!start || projectDate >= start) && (!end || projectDate <= end);
  });

  const fetchProjects = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data
      let token = localStorage.getItem('token');
      const response = await GetData('http://34.96.173.75:3000/api/v1/project/projectList', token);
      
      setProjects(response.data); // Update projects state with fetched data
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      console.error('Error fetching projects:', error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset page when changing rows per page
  };

  const handleOpenDialog = (isEdit) => {
    setIsProjectEdit(isEdit);
    setOpenDialog(true);
  };

  const handleDeleteDialog = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const handleMultiDeleteDialog = () => {
    setOpenMultiDeleteConfirmationDialog(true);
  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenDeleteConfirmationDialog(false);
  };


  const handleMultiCloseConfirmationDialog = () => {
    setOpenMultiDeleteConfirmationDialog(false);
  }

  const handleCloseProjectCreateSuccessDialog = () => {
    setOpenProjectCreateSuccessDialog(false);
  }

  const navigateAudience = () => {
    const data = { projectName: projectName }

    navigate('/AudienceList', { state: data });
  }

  //Project Options dialog
  const showProjectOptions = () => {
    setProjectOptopenDialog(true);
  };

  const closeProjectOptDialog = () => {
    setProjectOptopenDialog(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className='inner-wrapper'>
      <Grid container>
        <Grid item xs={12}><Navbar /></Grid>
        <Grid item xs={1}><Sidebar /></Grid>
        <Grid item xs={11}>
          <div className='content-wrapper'>
             
            <h2>Project</h2>

            <div className='sub-title'>Description</div>
            <div className='search-card'>
              <Box
                sx={{
                  width: '60%',
                  display: 'inline-flex'
                }}>
                <TextField
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{ width: '60%' }}
                  variant="standard"
                  placeholder='Search by Project Name, Created By'
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                />

                <TextField
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  sx={{ width: '20%' }}
                  variant="standard"
                  type="date"
                />

                <TextField
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  sx={{ width: '20%' }}
                  variant="standard"
                  type="date"
                />
              </Box>
              <Box
                sx={{
                  width: '40%',
                  display: 'inline-flex'
                }}>

                {/* <Button variant="text" size="small" >DELETE</Button> */}
                <Button
                  variant={checkedCheckboxes.length > 0 ? "contained" : "text"}// Check if checkbox is checked
                  onClick={() => handleMultiDeleteDialog()}
                >DELETE</Button>
                <Button variant="contained" size="small" onClick={(event) => handleOpenDialog(false)}>
                  CREATE PROJECT
                </Button>
              </Box>
            </div>
            {loading ? ( // Show loader if loading is true
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>No.</TableCell>
                      <TableCell>Account Name</TableCell>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProjects
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((project) => (
                        <TableRow key={project.id}>
                          <TableCell>
                            <input
                              type='checkbox'
                              value={project.id}
                              checked={checkedCheckboxes.includes(project.id)}
                              onChange={handleCheckboxChange}
                            />
                          </TableCell>
                          <TableCell>{project.id}</TableCell>
                          <TableCell>{project.account_name}</TableCell>

                          <TableCell><Link to={`/CreateAudience?projectName=${project.project_name}`} ref={itemWithIdRef} id={project.project_name}>{project.project_name}</Link></TableCell>
                          <TableCell>{formatDate(project.created_at)}</TableCell>
                          <TableCell>{project.created_by}</TableCell>
                          <TableCell>
                            <Button
                              id="basic-button"
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={(event) => handleClick(event, project)}
                            >
                              <MoreVertIcon />
                            </Button>

                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem onClick={navigateAudience}>View Detail</MenuItem>
                              <MenuItem onClick={(event) => handleOpenDialog(true)}>Rename</MenuItem>
                              <MenuItem onClick={() => handleDeleteDialog()}>Delete</MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>






                  <DialogConfirmation
                    open={openDeleteConfirmationDialog}
                    onClose={handleCloseConfirmationDialog}
                    title="Confirmation"
                    content="Are you sure you want to proceed?"
                    onYesClick={() => deleteProject(false)} 
                    maintitle="Delete Project?"
                    subtext="Are you sure you want to delete this project? This action cannot be undone."
                    buttonvalue="Delete"
                    success={delProjStatus.success}
                    error={delProjStatus.error}
                    isSuccess='false'
                  />

                  {/* Multiple Proj - delete */}
                  <MultiDelDialogConfirmation
                    open={openMultiDeleteConfirmationDialog}
                    onClose={handleMultiCloseConfirmationDialog}
                    title="Confirmation"
                    content="Are you sure you want to proceed?"
                    onYesClick={() => deleteProject(true)} 
                    maintitle="Delete Project?"
                    subtext="Are you sure you want to delete this project? This action cannot be undone."
                    buttonvalue="Delete"
                    success={delProjStatus.success}
                    error={delProjStatus.error}
                    isSuccess='false'
                  />

                  <DialogProjCreateSuccess
                    open={openProjectCreateSuccessDialog}
                    onClose={handleCloseProjectCreateSuccessDialog}
                    title="Success"
                    onYesClick={() => deleteProject(false)} 
                    maintitle="Success!"
                    subtext={createProjStatus.success}
                    buttonvalue="CREATE AUDIENCE"
                    success={delProjStatus.success}
                    error={delProjStatus.error}
                    isSuccess='true'
                  />

                  <Dialog open={openDialog} onClose={handleCloseDialog} className='modal-md'>
                    <DialogTitle className='modal-lg-text'>Create Project</DialogTitle>
                    <DialogContent>
                      <TextField
                      placeholder='Enter Name'
                        value={projectName}
                        onChange={handleProjectNameChange}
                        fullWidth
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog}>Cancel</Button>
                      <Button onClick={handleCreateProject} disabled={isButtonClicked} variant="contained" color="primary">
                        {isProjectEdit ? 'UPDATE' : 'CREATE'}
                      </Button>
                    </DialogActions>
                    {createProjStatus.success && (
                      <div className="success-message">{String(createProjStatus.success)}</div>
                    )}

                    {createProjStatus.error && (
                      <div className="error-message">{String(createProjStatus.error)}</div>
                    )}
                  </Dialog>

                </Table>
              </TableContainer>
            )}
            <Pagination
              count={Math.ceil(projects.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              style={{ marginTop: '16px', float: 'right' }}
            />

          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectList;
