import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const PageInfoModalBox = () => {
  const location = useLocation();
  const state = location.state;

  // Check if state exists and extract the data
  const { text1, text2, text3, text4 } = state || {};

  
  return (
    <div className='login-card'>
      {/* <Navbar /> */}
      <div className='modal-box'>
        <div className='modal-box-content'>
          <div className='error-message'>{text1}</div>
        <h2>{text2}</h2>
        <div className='modal-box-subtext'>{text3}</div>
        <div className='modal-box-text'>{text4}</div>
        </div>
      </div>
      
    </div>
  );
};

export default PageInfoModalBox;
