import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/navbar';
import APIS from './APIS';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
    };
  }

  handleClick = async () => {
    const { email } = this.state;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });

      try {
        const formData = {
          email: this.state.email
        };

        const response = await APIS.ForgotPassword(formData);
        if (response.code == 200) {
        
          const navigate = this.props.navigate;
          const displaydata = { text1: 'We have received your request', 
          text2: 'Reset password link was sent', 
          text3: 'Use the link we sent you to create your new password.',
          text4: 'Please check your email'};
          
          navigate('/PageInfoModalBox', { state: displaydata });

        } else {
          console.error('Login failed:', response.message);
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
    }
  };

  render() {
    const { email, emailError } = this.state;

    return (
      <div className='login-card'>
        <Navbar />
        <h2>Reset Password</h2>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <div className='info-sec'>Enter your login email and we’ll send you a link to reset your password.</div>
            <TextField
              label="Email"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              value={email}
              type='email'
              onChange={(e) => this.setState({ email: e.target.value })}
              error={emailError}
              helperText={emailError ? 'Enter a valid email address' : ''}
              required
              placeholder='Enter Email'
            />
            <Button variant="contained" size="medium" onClick={this.handleClick}>RESET PASSWORD</Button>
          </div>
        </Box>
      </div>
    );
  }
}

// Wrap the class component with a functional component that provides the navigate prop
const ResetPasswordWithNavigate = (props) => {
  const navigate = useNavigate();
  return <ResetPassword {...props} navigate={navigate} />;
};

export default ResetPasswordWithNavigate;
