import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/navbar";
import { List, ListItem, Snackbar, IconButton } from "@mui/material";
import { mobileRegx, emailRegx, passwordRegex } from "../helper/constant";
import { useRegisterContext } from "../Components/RegisterContext";
import APIS from "./APIS";
import { Close } from "@mui/icons-material";

function Registration() {
  const navigate = useNavigate();
  const { setRegisterData, registerData } = useRegisterContext();
  const [formData, setFormData] = useState({
    ...registerData,
    accountId: "214476",
    userType: "email",
  });

  const [registrationStatus, setRegistrationStatus] = useState({
    success: null,
    error: null,
  });

  const [validationErrors, setValidationErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isTermsVisited, setIsTermsVisited] = useState(false);
  const [isPrivacyPolicyVisited, setIsPrivacyPolicyVisited] = useState(false);
  const [duplicateError, setDuplicateError] = useState("");
  const [validMobileNo, setValidMobileNo] = useState(true);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const showPrivacyPolicy = () => {
    setRegisterData(formData);
    setIsPrivacyPolicyVisited(true);
    navigate("/PrivacyPolicy");
  };

  const showTerms = () => {
    setRegisterData(formData);
    setIsTermsVisited(true);
    navigate("/Terms");
  };

  const register = async () => {
    setIsFormSubmit(true);

    // Reset validation errors
    let validationErrors = {
      password: "",
      confirmPassword: "",
    };

    // Validate required fields

    let isFormValid = true;

    if (!formData.password || !passwordRegex.test(formData.password)) {
      validationErrors = {
        ...validationErrors,
        password: !formData.password
          ? "New Password is required."
          : "Password must contain at least 1 alphabet, 1 digit, and 1 special character.",
      };
      isFormValid = false;
    }

    if (
      !formData.confirmPassword ||
      formData.password !== formData.confirmPassword
    ) {
      validationErrors = {
        ...validationErrors,
        confirmPassword: !formData.confirmPassword
          ? "Confirm New Password is required."
          : "Passwords do not match.",
      };
      isFormValid = false;
    }
    const isValidEmail = emailRegx.test(formData.email);
    if (!isValidEmail) {
      isFormValid = false;
    }
    const isValidPhone = mobileRegx.test(formData.mobileNo);
    if (!isValidPhone) {
      isFormValid = false;
    }
    if (!isFormValid) {
      setIsValidEmail(isValidEmail);
      setValidationErrors(validationErrors);
      setValidMobileNo(isValidPhone);
      return;
    }

    // Check for empty required fields
    const requiredFields = ["firstName", "lastName", "email", "mobileNo"];
    const isEmptyField = requiredFields.some((field) => !formData[field]);

    if (isEmptyField) {
      setRegistrationStatus({
        success: null,
        error:
          "Please fill in all required fields and enter a valid email address.",
      });
      return;
    }
    setIsButtonDisabled(true);
    const response = await APIS.checkEmailDuplicate({ email: formData.email });
    if (response.code == 200) {
      setRegisterData({ ...formData });
      navigate("/MarketingConsent", { state: formData });
    } else if (response.code == 409) {
      setDuplicateError(response.message);
    }
    setIsButtonDisabled(false);
  };

  const handleCloseSanckBar = () => {
    setDuplicateError("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="login-card">
      <Snackbar
        open={duplicateError ? true : false}
        autoHideDuration={10000}
        onClose={handleCloseSanckBar}
        message={duplicateError}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <Navbar></Navbar>
      <h2>Register</h2>

      {/* {registrationStatus.success && (
        <div className="success-message">{registrationStatus.success}</div>
      )}

      {registrationStatus.error && (
        <div className="error-message">{registrationStatus.error}</div>
      )} */}

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Box
            sx={{
              width: "50%",
              display: "inline-flex",
            }}
          >
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName || ""}
              placeholder="Enter First Name"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              onChange={handleInputChange}
              required
              error={formData.firstName == "" && isFormSubmit}
              helperText={
                formData.firstName == "" && isFormSubmit
                  ? "Please enter first name."
                  : ""
              }
            />
          </Box>

          <Box
            sx={{
              width: "50%",
              display: "inline-flex",
            }}
          >
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName || ""}
              placeholder="Enter Last Name"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              onChange={handleInputChange}
              required
              error={formData.lastName == "" && isFormSubmit}
              helperText={
                formData.lastName == "" && isFormSubmit
                  ? "Please enter last name."
                  : ""
              }
            />
          </Box>
        </div>

        <div>
          <TextField
            label="Email"
            name="email"
            placeholder="Enter Email"
            value={formData.email || ""}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            onChange={handleInputChange}
            required
            error={!isValidEmail}
            helperText={
              !isValidEmail
                ? "Please enter a valid email address."
                : "Please enter email"
            }
          />
        </div>

        <div>
          <TextField
            label="New Password"
            placeholder="Enter new password"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            value={formData.password || ""}
            name="password"
            type="password"
            onChange={handleInputChange}
            required
            error={!!validationErrors.password}
            helperText={validationErrors.password}
          />
          <div className="info-sec">
            Please make sure your password contains at least 1 character from
            all of the following
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
                p: 0,
                m: 0,
              }}
            >
              <ListItem sx={{ display: "list-item" }}>Alphabet</ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Lower and upper case
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Special character
              </ListItem>
            </List>
          </div>
          <div>
            <TextField
              label="Confirm New Password"
              placeholder="Enter confirm new password"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              value={formData.confirmPassword || ""}
              name="confirmPassword"
              type="password"
              onChange={handleInputChange}
              required
              error={!!validationErrors.confirmPassword}
              helperText={validationErrors.confirmPassword}
            />
          </div>
        </div>

        <div>
          <TextField
            label="Contact No."
            name="mobileNo"
            placeholder="Enter Contact No."
            value={formData.mobileNo || ""}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            onChange={handleInputChange}
            required
            type="number"
            error={!validMobileNo}
            helperText={!validMobileNo ? "Please enter contact number." : ""}
          />
        </div>

        <div>
          <TextField
            label="Job Title"
            name="jobTitle"
            value={formData.jobTitle || ""}
            placeholder="Enter Job Title"
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
            variant="standard"
          />
        </div>
        <div>
          <TextField
            label="Company"
            name="company"
            value={formData.company || ""}
            placeholder="Enter Company"
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
            variant="standard"
          />
        </div>
        <div className="center">
          <span className="font-md">By signing up, you agree to our </span>
          <span className="font-md c-black link" onClick={showTerms}>
            Terms of Use
          </span>
          <span> and </span>
          <span className="font-md c-black link" onClick={showPrivacyPolicy}>
            Privacy Policy
          </span>
        </div>
        <Button
          variant="contained"
          size="medium"
          onClick={register}
          disabled={isButtonDisabled}
        >
          REGISTER
        </Button>
        <div className="center" sx={{ m: 20 }}>
          <span className="font-md">Already have an account?</span>
          <span>
            <Link className="c-red font-md pd-l-10" to="/login">
              Login
            </Link>
          </span>
        </div>
      </Box>
    </div>
  );
}

export default Registration;
