import { Typography } from "@mui/material";

function Terms() {
  return (
    <div className="login-card privacy-card">
      <Typography variant="h4" mb={2} fontWeight={"bolder"}>
        Privacy Policy of True Digital Group
      </Typography>
      <div className="font-md">
        <br></br>
        <div>
          <b>1. Introduction</b>
        </div>
        <br></br>
        <div>
          True Digital Group Co., Ltd., and its respective subsidiaries,
          affiliates, associated companies and jointly controlled entities
          (collectively, “Company”, “We”, “us”, or “our”) attach great
          importance to customer privacy, hence we provide this privacy notice
          to inform our customers (“you”) of our policy with regard to your
          personal data, the methods on how we collect, use and disclose your
          personal data, the types of personal data we collected, the purpose of
          personal data processing, the disclosure of your personal to third
          parties, the details on security measures in place for your personal
          data, the retention period of personal data, and the rights you have
          in accordance with Personal Data Protection Act, B.E. 2562 and other
          related applicable laws and regulations (“Personal Data Protection
          Law”)
        </div>
        <br></br>
        <div>
          We may publish a policy in addition to this privacy policy with regard
          to our specific products and/or services (“Additional Policy”) from
          time to time. If there is any inconsistency between the terms of the
          Additional Policy and this Privacy Policy of True Digital Group, then
          the Additional Policy of the specific products and/or services shall
          prevail.
        </div>
        <br></br>
        <div>
          This Privacy Policy of True Digital Group and the Additional Policy
          shall apply to all personal data that is processed in relation to our
          products and/or services in the countries where our products and/or
          services are being provided, used or subscribed. (collectively,
          “Privacy Policy”).
        </div>
        <br></br>
        <div>
          <b>2. Contact Us</b>
        </div>
        <br></br>
        <div>
          You can contact us at 101 True Digital Park, Griffin Building, 9-12
          Floor, Sukhumvit Road, Bangchak, Phra Khanong, Bangkok 10260, Thailand
          or contact our data protection officer for more information about the
          Privacy Policy via Dataprivacycenter@truedigital.com.
        </div>
        <br></br>
        <div>
          For TrueID users who reside outside Thailand, please contact our team
          in your country for more information, the details of which are
          provided on the Additional Policy of TrueID.
        </div>
        <br></br>
        <div>
          <b>
            3. The Purpose of the Collection, Use and Disclosure of Personal
            Data
          </b>
        </div>
        <br></br>
        <div>
          We will collect, use and disclose (collectively referred to as
          “process”) the personal data only in the case where it is necessary
          and permitted by law. We will process the personal data provided that
          there is a lawful basis to do so which includes processing of personal
          data in case it is necessary for the performance of contract we have
          with you, it is necessary for compliance with our legal obligations,
          it is of our legal interests, it is to prevent or suppress a danger to
          your or any other person’s life, body and health, it is for carrying
          out a public interest task or for exercising official authority, it is
          to proceed according to your consent given to us, and/or other lawful
          basis as provided by the Personal Data Protection Law.
        </div>
        <br></br>
        <div>
          The law requires us not to collect sensitive personal data including
          but not limited to the data relating to the race, ethnicity, political
          views, ideological belief, religion or philosophy, sexual behavior,
          criminal history, health data, disability, Labor Union membership
          data, genetic data, biological data, or any other data which may
          affect you in the same manner, unless we have your explicit consent.
          We will not collect or use this type of data without your explicit
          consent unless the law permits us to do so.
        </div>
        <br></br>
        <div>
          Subject to the applicable services and/or products available in the
          countries under the Additional Policy, we may collect your personal
          data for processing for the following purposes:
        </div>
        <br></br>
        <div>
          <b>
            {" "}
            3.1 To perform our obligations under the contract we have with you
          </b>
        </div>
        <br></br>
        <div>
          We will process your personal data to deliver our service as applied
          or requested by you, and/or to fulfill contractual obligations we have
          with you, including but not limited to the following purposes:
        </div>
        <ol>
          <li>
            For your subscription and sign-in to our account (such as TrueID
            account).
          </li>
          <br />
          <li>
            To authenticate your registration and to verify and authenticate
            your identity as well as contact channels.
          </li>
          <br />
          <li>
            To provide services for which you have subscribed, preferred,
            interested or requested, and/or to comply with the contract you made
            with us (such as signing up for TrueID, TrueYou, True Digital
            Academy, Online Station and other products and services).
          </li>
          <br />
          <li>
            To communicate for relevant products and services including sending
            notifications on service charges, goods and service purchase orders,
            reminders for payments of goods and services, and the receipts
            thereof.
          </li>
          <br />
          <li>
            To pay for our service charges and other fees associated with our
            services.
          </li>
          <br />
          <li>
            To remunerate or give rewards or other benefits/privileges to you.
          </li>
          <br />
          <li>
            To answer your questions, provide support and information, receive
            complaints and solve problems and difficulties for you.
          </li>
          <br />
          <li>
            To carry out our business such as conducting analysis and research,
            measuring performance efficiency, ensuring development and
            maintenance of the existing services to be delivered properly.
          </li>
          <br />
          <li>To compensate you for damages.</li>
          <br />
          <li>
            To notify you of any change to our service and terms of service.
          </li>
          <br />
        </ol>
        <br></br>
        <div>
          <b> 3.2 To Comply with our legal obligations</b>
        </div>
        <br></br>
        <div>
          We have to comply with many applicable laws and orders made by the
          relevant legal authorities, hence we are required to collect, use and
          disclose your personal data, including but not limited to the
          following purposes:
        </div>
        <ol>
          <li>To comply with the Personal Data Protection law.</li>
          <br />
          <li>
            To comply with laws related to us (such as Computer-related Crime
            Law, Electronic Transaction Law, Consumer Protection Law and other
            laws which we are bound to comply with.).
          </li>
          <br />
          <li>
            To comply with regulations and/or orders made by legal authorities
            (such as the court’s orders, the government authorities’ order and
            the orders made by the authorized government officials)
          </li>
          <br />
        </ol>
        <div>
          <b> 3.3 For legitimate interests of us and third parties</b>
        </div>
        <br></br>
        <div>
          We process your personal data for our legitimate interests in
          consideration of our own interests or any other individuals or
          companies’ interests and the fundamental rights to your personal data,
          including but not limited to the following purposes:
        </div>
        <ol>
          <li>To comply with the Personal Data Protection law.</li>
          <br />
          <li>
            To prevent cheating and fraud including the enforcements of our
            terms, conditions, policies and/or the exercise of rights of claims
            under the relevant law.
          </li>
          <br />
          <li>
            To ensure security operations of the data and our service networks
            and to report you if any suspicious activity should be found such as
            an unusual attempt to sign in to your account.
          </li>
          <br />
          <li>
            To study how our customers use products and services from us and
            improve our standard of services, as well as planning and tracking
            down marketing campaigns and analysing and improving the services
            subscribed by you for effectiveness and meeting your needs.
          </li>
          <br />
          <li>
            To develop new products suitable for customers’ needs and categorize
            customer types for new products or services.
          </li>
          <br />
          <li>
            To present our contents, offers and benefits based on your
            information and interests. However, if you would like to stop
            receiving our marketing communications, you are able to opt out at
            the setting page of the application you use, or you may unsubscribe
            our email communication through the provided unsubscription button
            in your email.
          </li>
          <br />
          <li>
            To contact you and record video or voice associated with arrangement
            of meetings, seminar, entertainment or booth exhibition.
          </li>
          <br />
          <li>To anonymise or de-identify personal data.</li>
          <br />
        </ol>
        <div>
          <b> 3.4 To proceed according to your consent given to us</b>
        </div>
        <br></br>
        <div>
          We may need your consent, if required by Personal Data Protection Law,
          before processing your personal data, including but not limited to the
          following purposes:
        </div>
        <ol>
          <li>To collect sensitive personal data, if necessary.</li>
          <br />
          <li>
            To offer relevant deals and promotion from selected partners and
            advertise all contents that match your interest and information
            about you.
          </li>
          <br />
          <li>
            To share your personal data to the companies within True Corporation
            Public Company Limited (“True Group Companies”)*, and/or the
            Company’s or True Group Companies’ selected partners as well as
            Charoen Pokphand Group companies for analysing your behaviours in
            product and service usage and creating customer database, for
            researching and developing product and service as well as planning
            marketing strategies to achieve the greatest benefits in developing
            products and services which satisfy your needs, and for offering
            products, services and privileges of the Company, True Group
            companies, or selected partners of the Company and True Group
            companies as well as Charoen Pokphand Group companies which are
            properly personalised for you.
          </li>
          <br />
          <li>
            We may collect, use and disclose your personal data which is
            beneficial for you to better enjoy our products and services. We
            will provide you with clear details of the consent, in accordance
            with the Personal Data Protection Law such as a login to third-party
            service provider with TrueID’s user and account ID.
          </li>
          <br />
          <li>
            To send or transfer your personal data to other countries with
            inadequate standards of data protection (unless the Personal Data
            Protection Law provides that it could be proceeded without consent.)
          </li>
          <br />
          <li>
            In case you are a minor, incompetent or quasi-incompetent whose
            consent must be given by parents, guardian or curator (as the case
            may be) (unless the Personal Data Protection Law provides that it
            could be proceeded without consent.)
          </li>
          <br />
        </ol>
        <div>
          <b>4. Personal Data That We Collect</b>
        </div>
        <br></br>
        <div>
          <b>
            This Subject describes the types of personal data which is covered
            and protected by the applicable law.
          </b>
        </div>
        <br />
        <div>
          We collect many types of personal data. With that being said,
          collection, use and disclosure of your personal data may vary
          according to the products and/or services available in the country
          which you opt for or used to opt for. In order to give you an overview
          of all the data we have collected, we have classified the types of
          data in groups as follows:
        </div>
        <br></br>
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 20px",
            width: "100%",
          }}
        >
          <tr style={{ height: "20px" }}>
            <th style={{ textAlign: "left", fontWeight: "bold" }}>
              Types of personal data
            </th>
            <th style={{ textAlign: "left", fontWeight: "bold" }}>Details</th>
          </tr>
          <tr>
            <td>Personal data</td>
            <td>
              Including name, surname, sex, age, date of birth, nationality.
            </td>
          </tr>
          <tr>
            <td>Contact data</td>
            <td>
              Including current address, e-mail address, telephone number.
            </td>
          </tr>
          <tr>
            <td>Official identity document data</td>
            <td>
              Including identification number, passport number, a copy or
              photocopy of ID card and passport.
            </td>
          </tr>
          <tr>
            <td>User account data</td>
            <td>Including account name, account number, account password.</td>
          </tr>
          <tr>
            <td>Financial data</td>
            <td>Including payment data and payment confirmation.</td>
          </tr>
          <tr>
            <td>Service usage data</td>
            <td>
              Including products and services you have subscribed and used, the
              duration and date you have signed in and used the service, IP
              address, cookie data, usage activity data.
            </td>
          </tr>
          <tr>
            <td>Customer preference data</td>
            <td>Including the content you like.</td>
          </tr>
          <tr>
            <td>Market research and marketing data</td>
            <td>Including customer opinion survey.</td>
          </tr>
          <tr>
            <td>Equipment, application and browser data</td>
            <td>
              Including equipment identification number or other unique
              identifiers, equipment type, model and brand, application and
              browser details, operating system and platform.
            </td>
          </tr>
          <tr>
            <td>
              Voice conversion data between you and our customer service
              division
            </td>
            <td>Including voice recording data.</td>
          </tr>
          <tr>
            <td>Location information</td>
            <td>
              Including GPS information, information about objects nearby
              equipment (such as Wi-Fi access point, mobile phone signal tower
              and Bluetooth activation).
            </td>
          </tr>
          <tr>
            <td>Social media data</td>
            <td>
              Including social media account, your profile data, usage and
              contacts with our social media.
            </td>
          </tr>
          <tr>
            <td>Other data</td>
            <td>Including other data you give to us through any channels.</td>
          </tr>
        </table>
        <br />
        <h4>Information you tell us</h4>
        <p>
          We collect information that you directly tell us when you use the
          Service. This will include the following:
        </p>
        <p>
          <b>Account information:</b> If you create an Adtech account We may
          require you to provide a username and password. Your username will be
          publicly known information. And it doesn't have to be related to your
          real name. You may also provide other information about your account,
          such as your email address, biometrics, or profile picture. We will
          also store your user account preferences and settings.
        </p>
        <p>
          <b> Content You Submit:</b> We collect the content you submit to the
          Service. This will include your posts and comments. Including your
          messages sent to other users. (such as conversations with users in the
          community) your report and other communications with regulators and
          with us. Your content may include text, links, images, gifts, and
          videos.
        </p>
        <p>
          <b> Your actions:</b> We collect information about your actions while
          using the Service, including your interactions with content such as
          liking, sharing, commenting, and reporting. We will also: Collect
          information about your interactions with the community, such as your
          moderator status.
        </p>
        <p>
          You or your administrator may integrate the Services with third-party
          applications. In such case, we will receive information that allows us
          to identify your account within the third-party applications (like
          name, email address, phone number, tokens, or other identifying
          information), the log data, content, and device information. The
          information or content we receive from your organization is subject to
          your organization’s privacy policies. When you integrate our Services
          with a third-party application, the information we receive depends on
          the settings, permissions, and privacy Statement controlled by that
          third-party application.
        </p>
        <p>
          In our application we have step to login to Oauth url via users Google
          ads account, to generate the Oauth code and refresh token after
          getting users consent. Refresh token will be used to generate the
          Access token to invoke the Google APIs to retrieve the users Google
          ads account name, to push the ads to users Google ads account.
        </p>
        <p>
          This app wants permission to do anything you can do on your Google Ads
          account, including:
        </p>
        <span>See your performance data</span>
        <span>Create, edit, or delete your campaigns, ad groups, and ads</span>
        <span>
          Create, edit, or delete your bidding, targeting, and scheduling
          settings
        </span>
        <span> Set and change your budgets</span>
        <span>See, apply, and dismiss your recommendations</span>
        <span>Edit your billing settings</span>
        <span>
          There may be sensitive information in your accounts, such as financial
          information or performance metrics.
        </span>
        <p>
          If your Google Account's access is limited to read-only access,
          email-only access, or any other restriction, then this app's access
          will be limited in the same way. For example, if you only have
          read-only access to the account, then this app will be able to pull
          reports but not make changes to the account.
        </p>
        <p>
          Over time the app's ability to access this account will follow that of
          your Google Account. So if your access to the account is revoked, then
          this app won't be able to access it either.
        </p>
        <p>
          <b>Other Information:</b>You may choose to provide other information.
          us directly. For example, we may collect information if you fill out a
          form. Participate in events or promotions sponsored by Adtech. Getting
          help for customers or communicating with us
        </p>
        <h4>Information We Collect Automatically</h4>
        <p>
          When you access or use our services We may automatically collect
          information about you. This will include the following information:
        </p>
        <p>
          <b>Log Data and Usage Data:</b> We may log information when you access
          and use the Service. This may include the IP address string between
          the user-agent. Browser type Operating system Forwarding URL
          Information about the device (such as device identifiers), device
          settings The page you visited The link you clicked Requested URL and
          the values used in the search
        </p>
        <p>
          <b>Information collected from cookies and similar technologies:</b> We
          may receive pieces of information that your browser stores and returns
          to us upon request, including similar technologies. We will use this
          information to improve your experience. Understand user activity
          Personalize content and ads to meet your individual needs. Including
          improving the quality of our services. For example, we will store and
          retrieve information about your chosen language and other settings.
        </p>
        <p>
          <b> Networks and Connections:</b> We collect information about the
          people, pages, accounts, hashtags and groups you are connected to.
          This includes how you interact with those people through the Services,
          such as the people you talk to most or the groups you are a member of.
          Again, we collect contact information if you choose to upload, sync,
          or import it from your device. This information (such as telephone
          directories or call logs or messaging logs) which we use to do things
          such as helping you and others find people you may know and for other
          purposes. This may include information about you, such as when others
          share or comment on your photos. Send a message to you or upload,
          sync, or import your contact information.
        </p>
        <p>
          <b> Information about the device:</b> We collect information from and
          related to computers, mobile phones. Connected TVs, Smart TVs and
          other devices used to connect to the website of which you are a user
          in conjunction with the service And we will take this information from
          various devices. For example, we will use information collected
          related to your use of services via your mobile phone in order to
          create content that is more relevant to you. (including
          advertisements) or features you see when you use the Service on
          another device, such as your laptop or tablet computer or in order to
          measure whether you have taken any action This is in response to
          advertisements we display to you on mobile phones or other devices. Is
          it yours or not? The information we get from the above mentioned
          devices will include:
        </p>
        <ul>
          <li>
            {" "}
            Device properties - information such as operating system Hardware
            and software version Remaining battery level signal strength
            Remaining storage space Browser type The application includes the
            name and type of the data file as well as the plug-in.
          </li>
          <li>
            Identifiers - Unique identifiers, device IDs, and other identifiers,
            such as identifiers from game applications. or the various accounts
            you use, including device IDs in the same family
          </li>
          <li>
            Information from device settings - Information you allow us to
            receive through device settings you enable, such as accessing your
            location from your GPS, camera, or photos.
          </li>
          <li>
            Network and connectivity - Information such as the name of your
            mobile or internet service provider, language, time zone, mobile
            number, IP address, connection speed. and in some cases may include
            information about other devices. that are close to you or in the
            same network as you So that we can do things like help you stream
            video from your mobile phone to your TV.
          </li>
        </ul>
        <h4>Methods used to share your information</h4>
        <p>
          Content that others have shared or reshared that is relevant to you
        </p>
        <p>
          Add people to consider who you share information with. This is because
          people who can see your activity on the Service can choose to share
          that activity with others, whether through the Service or not. This
          includes people and businesses that are not viewers of the content you
          share, for example: When you share a post or send a message to a
          friend or account. They may download, screenshot, or otherwise share
          the content with others within the Service or outside of the Service.
        </p>
        <div>
          <b>5. Sources of Personal Data We Collect</b>
        </div>
        <br></br>
        <div>
          <b>
            This Subject gives details of the personal data sources received by
            us.
          </b>
        </div>
        <div>
          Generally, we will collect personal data directly from you, and we
          will automatically collect your personal data when you use our
          products and/or services or contact us. In addition, we will collect
          personal data from our partners and from other sources which, in this
          respect, we shall adhere to and comply with the process defined by law
          and in the case permitted by law only, under the details as follows:
        </div>
        <br></br>
        <div>
            Refer the link for Google API Services User Data Policy - <a href="https://developers.google.com/terms/api-services-user-data-policy" target='_blank'>link</a>
          </div>
          <br/>
        <div>
          <b> 5.1 Personal data you create and provide to us</b>
        </div>
        <br></br>
        <div>
          This data covers the data you provide to us and data given by people
          related to you or your business including those authorized by you or
          acting on your behalf as the following cases:
        </div>
        <ul>
          <li>When you have registered for using our services.</li>
          <br />
          <li>
            When you have communicated with us via website, application, email,
            social media and phone.
          </li>
          <br />
          <li>
            When you have answered our survey or joined our marketing
            promotions.
          </li>
          <br />
          <li>
            When you have agreed to receive news and information on the updates
            and benefits.
          </li>
          <br />
          <li>
            When you have conducted activities on our services including
            comments, uploading contents, images or videos on our service,
            profile updates and account settings.
          </li>
          <br />
          <li>
            In the event that you wish to establish a proof and a verification
            of your identity with us, we will request you to forward to us the
            additional government identity documents and other documents.
          </li>
          <br />
          <li>
            Upon your payments for our services, we shall keep the
            payment-related data.
          </li>
          <br />
        </ul>
        <div>
          <b> 5.2 Personal data automatically collected by us</b>
        </div>
        <br></br>
        <div>
          Upon your access to use our services and products through our websites
          and/or applications, we shall collect your data as follows:
        </div>
        <ul>
          <li>
            Data on your service usage, your communications with us,
            applications and browsers including the electronic device-related
            data used in the services or communications with us.
          </li>
          <br />
          <li>
            When you use our products and/or services in which it is necessary
            to specify the location of your device, we shall collect data on
            your device’s location enabling us to provide various features such
            as offerings on the services, discounts, and benefits for stores or
            restaurants near you. You can control the storage of these data
            through your device settings, which may differ depending on the
            operating system.
          </li>
          <br />
          <li>
            In addition, we use Cookie and other tracking technologies in
            collecting the data while you are using our websites or mobile
            applications. (You can learn more information about this in the
            “Cookies and use of cookies” section, section 9 of this notice.) You
            may opt out of collecting data through cookies via your browser
            setting. However, this may reduce full functionality of the use of
            our website and application.
          </li>
          <br />
        </ul>
        <div>
          <b> 5.3 Personal data from other sources</b>
        </div>
        <br></br>
        <ul>
          <li>The companies that introduce you to us. </li>
          <br />
          <li>
            True Group Companies and the Company’s or True Group Companies’
            selected partners as well as Charoen Pokphand Group companies which
            are lawfully entitled to disclose personal data to us{" "}
          </li>
          <br />
          <li>
            Data we receive from the company in which you are a director,
            authorised person, agent, assigned or contact person.
          </li>
          <br />
          <li>
            Internet service providers and telecommunications service providers.
          </li>
          <br />
          <li>
            Social media service providers and other technology providers (for
            example: when you use user IDs or credentials to log into the
            service providers’ account for using our services, we shall collect
            your profile data. (You may control any setting related to
            collecting of these data through the options that those service
            providers have granted the rights in your user account).
          </li>
          <br />
          <li>
            Public data sources such as data from the open government sector
            databases.
          </li>
          <br />
          <li>
            Government agencies and law enforcement agencies such as the
            Department of Provincial Administration to check the status of ID
            cards.
          </li>
          <br />
        </ul>
        {/* Section 6 */}
        <div>
          <b>6. Personal Data Sharing and Disclosure</b>
        </div>
        <br></br>
        <div>
          We may share your personal data with the external individuals or
          organizations for various purposes specified in this Privacy Policy
          which, in this respect, the external organizations with which we may
          share your personal data can be categorized as follows:
        </div>
        <br></br>
        <ul>
          <li>
            <b>
              True Group Companies, or the Company’s or True Group Companies’
              selected partners as well as Charoen Pokphand Group companies
            </b>
          </li>
          <br />
          <li>
            <b>Government agencies</b>
          </li>
          <br />
          <li>
            <b>Other service providers related to our products or services</b>
          </li>
          <br />
        </ul>
        <br />
        <div>
          The companies which we may desire to share your personal data due the
          reason that they are involved with our products or services and/or the
          fact that you have used our services and/or for the reason to comply
          with the contract which you have with us, and/or as requested by you.
        </div>
        <br></br>
        <ul>
          <li>
            <b>Service providers supporting our business</b>
            <div>
              The companies which we have used to help us in carrying out our
              business operations, business improvements and developments and
              that these service providers may need to have access to your
              personal and other data provided, however, that we do not allow
              such service providers to use or disclose your personal data for
              other purposes except only on those associated with the services
              which we have contracted them to carry on. These companies may
              include:
              <ul>
                <li>The companies that introduce you to us.</li>
                <li>
                  The companies providing the customer management services.
                </li>
                <li>
                  The banks and providers of payment and transaction services.
                </li>
                <li>
                  Business consultants including law firms and information
                  technology consultants.
                </li>
                <li>Information technology service providers.</li>
                <li>
                  Advertising, marketing and social media service providers.
                </li>
                <li>
                  Consultants for litigations and debt payment demands including
                  law firms.
                </li>
              </ul>
            </div>
          </li>
          <br />
          <li>
            <b>
              Merger & acquisition of company and product and service possession
              and transfer
            </b>
          </li>
          <br />
          <li>
            <b>Sharing and disclosure of anonymised data</b>
            <div>
              We may share your anonymised data with the general public and/or
              our affiliates, partners, and other companies with whom we have
              relationships: for instance, we will share information to show the
              trend on our service usage in general in order to enable us to get
              to understand the market demand trends.{" "}
            </div>
          </li>
          <br />
          <li>
            <b>Sharing data to other third parties with your consent</b>
            <div>
              We may share your personal and other provided data if you provide
              consent to us on a case-by-case basis such as binding your account
              with third-party platforms.
            </div>
          </li>
          <br />
          <li>
            <b>International transfer </b>
            <div>
              Your personal data may be transferred from one country, in which
              you reside and subscribe our services and/or products, to another
              country, the products and/or services of which you choose to opt
              for. When we transfer your personal data to another country, we
              will comply with the applicable laws and regulatory obligations in
              relation to your personal data with appropriate safeguards in
              place to ensure an adequate level of protection for the personal
              data.
            </div>
          </li>
          <br />
        </ul>
        {/* Section 6 end */}
        {/* Section 7 start */}
        <div>
          <b>7. Personal Data Handling and Personal Data Retention</b>
        </div>
        <br></br>
        <div>
          We have the effective security measures including organisational and
          technical measures to prevent personal data from being lost,
          accessible, used, altered, or disclosed without due authorization or
          illegally.
        </div>
        <div>
          It is necessary for us to keep your different types of data under
          different periods of time. Generally, we will keep your personal data
          as long as you are our customer. However, we may keep it as long as up
          to 10 years after you have ceased on being our customer for the
          following purposes:
        </div>
        <br></br>
        <ul>
          <li>
            To establish legal claims in accordance with the law, comply with or
            exercise a right of claims according to the law, and raising defense
            against legal claims.
          </li>
          <br />
          <li>
            To carry on security operations and prevent illegal activities,
            prohibited activities, violations in order to protect you, others
            and us from any acts of misconduct.
          </li>
          <br />
          <li>
            To conduct study on customers’ data as part of our own internal
            research.
          </li>
          <br />
          <li>
            For the purpose of making the accounts, settling the disputes and
            compliance with tax requirements.
          </li>
          <br />
        </ul>
        <div>
          After your personal data is no longer needed to be kept, we will
          delete it from our server or store it in the form of anonymised data.
        </div>
        {/* Section 7 end */}
        {/* Section 8 */}
        <br />
        <h4>
          8. In the Event You Prefer Not to Provide Your Personal Data to Us We
        </h4>
        <p>
          need to collect personal data according to the law or for use in
          entering into the contract which we have with you. If you prefer not
          to provide us with this personal data, we may not be able to provide
          services to you including our actions to probably cancel the products
          or services we provide to you. In some cases, we may ask for personal
          data which will be useful to allow you to use our products or services
          better. We shall specify expressly clear details when we need to ask
          for those personal data. You have a right to choose not to provide
          these additional details to us and it will not affect the main
          products or services that you have had with us.
        </p>
        {/* Section 9 */}
        <br />
        <h4>
          9. Your Rights to Have Control over Personal Data Subject to the
        </h4>
        <p>
          Personal Data Protection Law, once the provisions of Data Subject
          Rights in accordance with the Personal Data Protection Law become
          effective, you are able to exercise the right associated with your
          personal data in accordance with the requirements and conditions
          specified, under the details as follows:
        </p>
        <ul>
          <li>
            <b>Right to consent withdrawal ·</b>
          </li>
          <li>
            If you grant consent to us for a particular purpose, you may
            withdraw the consent at any time unless there is a restriction
            according to the law, or it is a consent in connection with an
            agreement which is beneficial to you.
          </li>
          <li>
            You have the right to withdraw a consent at any time. You can also
            make changes to the consent preference through the application you
            use our services.
          </li>
          <li>
            Your consent withdrawal may disallow us to deliver some products and
            services to you and we will notify you of them in detail.
          </li>
          <li>
            <b>Right to notify us of inaccurate personal data</b>
          </li>
          <li>
            You have the right to notify us of how your personal data is
            inaccurate or incomplete or out-of-date. We will investigate and
            rectify it to ensure accuracy.
          </li>
          <li>
            This includes your right to rectify and update your personal data
            through the application you use.
          </li>
          <li>
            <b>
              Right to access, obtain a copy and transfer of your personal data
            </b>
          </li>
          <li>
            You have the right to access and obtain a copy of your personal data
            from us, including requesting your personal data in an electronic
            format or delivering it to other organisations or companies to the
            extent specified by the Personal Data Protection Law.
          </li>
          <li>
            <b>
              Right to objection of processing, restriction, erasure or
              destruction of personal data
            </b>
          </li>
          <li>
            You have the right to submit a complaint to object the processing of
            your personal data, to restrict the use of your personal data, and
            to erase or destroy your personal to the extent specified by the
            Personal Data Protection Law.
          </li>
        </ul>
        <p>
          You could contact our staff to proceed with submission of complaints
          as above through dataprivacycenter@truedigital.com. For TrueID users
          in countries outside Thailand, please contact TrueID team in your
          country as specified in Section 2 Contact Us. In accordance with the
          Personal Data Protection Law, we reserve the right to dismiss the
          complaints which are unjustifiable, beyond the extent provided by law
          and/or impractical to satisfy. Moreover, we reserve the right to
          dismiss the complaints subject to the extent provided by law; however,
          you may file a complaint to the Office of Data Protection Commissioner
          or the relevant data privacy authority through the channel established
          by the announcement.
        </p>
        {/* Section 10 */}
        <h4> 10. Cookies and Use of Cookie</h4>
        <p>
          We may collect, use and disclose the cookies and other similar
          tracking technologies when you use our services and products,
          including when using our website and application, for the following
          purposes:
        </p>
        <ul>
          <li>To prevent you from fraudulent activities and to enhan</li>
          <li>
            To study how visitors use our website and application as well as
            other services so that we are able to make an appropriate
            improvement.
          </li>
          <li>
            To make a decision on which of our products or services may relate
            to you
          </li>
          <li>
            To tailor our marketing campaign that you may see on social media
            applications and websites.
          </li>
        </ul>
        <p>
          You may deny all cookies though your browser setting; however, this
          setting may reduce full functionality of the use of our website and
          application.
        </p>
        <br />
        {/* Section 11 */}
        <h4>11. Minors </h4>
        <p>
          We have no intention to collect personal data from minors, which means
          those who do not reach their legal age of 20 years old (or any legal
          age specified by the applicable law) or who are not of their legal age
          by marriage or any conditions as defined by the applicable law,
          <b> (“Minors”)</b>. We also have no intention to allow Minors to
          subscribe for using or purchasing our products, goods or services
          unless we obtain their sole consent (as permitted by the law) or we
          obtain consent from their parents or guardians who can act on behalf
          of the Minors or we could rely on other legal basis. If you are
          parents of a Minor, or guardians who can act on behalf of the Minor
          and are aware that your Minor under your custody has provided us with
          his/her personal data, please contact us and that, in this regard, you
          may request for the exercise of your right within the scope of the
          Personal Data Protection Law and the provisions stipulated by the
          other relevant Laws. If you are a Minor and necessarily require to
          receive our services and products, we request you to ask for consent
          from your parents or your guardians who can act on behalf of you to
          give us your personal data by contacting us as detailed above. In the
          event that the Company require a Minor’s consent in order to use
          personal data for the purpose that would not be permitted by the
          Minor’s sole consent in accordance with the provisions of the Civil
          and Commercial Code and/or the applicable law, the Company will obtain
          consent from the Minor’s parents or guardians who can act on behalf of
          the Minor subject to the criteria specified by the Personal Data
          Protection Law.
        </p>
        <br />
        {/* Section 12 */}
        <h4>12. Use of Personal Data for the Original Purposes</h4>
        <p>
          We are entitled to collect and use your personal data which were
          previously collected by us before the effective date of the Personal
          Data Protection Law in connection with collection use and disclosure.
          We are able to continue collecting and using such personal data
          according to the original purposes. If you no longer wish us to
          continue collecting and using such personal data, you may make changes
          to your consent preference setting via the application and/or website
          you use.
        </p>
        <br />
        <h4>13. How we protect your information </h4>
        <p>
          We will take measures to help protect information about you from loss,
          theft, misuse, and unauthorized access, disclosure, alteration. and
          unauthorized destruction We will also implement technical and
          administrative access control measures to limit access to our
          employees' non-public personal information.
        </p>
        <p>
          <span>How can I manage or delete information related to me?</span>
          When you delete your user account We will delete things. that you've
          posted, such as photos and your status updates And you will not be
          able to recover that information later. Information that other people
          have shared about you will not be considered part of your user account
          and will not be deleted.
        </p>
        <p>
          <b> Data Security.</b> takes reasonable steps to protect your personal
          information from loss, misuse, and unauthorized access, disclosure,
          alteration, or destruction, taking into account the risks involved in
          the processing and the nature of the personal data. However, no
          application, Internet, or email transmission is ever fully secure or
          error-free, and we do not guarantee the security of any personal
          information. You are also responsible for ensuring that your account
          and the passwords we provide you are protected.
        </p>
        <br />
        {/* Section 13 */}
        <h4>14. Changes in Privacy Policy </h4>
        <p>
          We will update and alter this Privacy Policy from time to time to be
          compatible with the changing laws, regulations or operational
          requirements. We shall make the express announcements accordingly on
          our applications, websites and/or notify you via e-mail. If you do not
          wish to acknowledge or accept any amendment to this Privacy Policy,
          you are entitled to cancel the use of our services.
        </p>
        <p>
          <div> *Remarks</div> True Group Companies includes True Corporation
          Public Company Limited, True Move H Universal Communication Co Ltd.,
          True Distribution & Sales Co Ltd., True Music Co Ltd., Telecom Asset
          Management CO Ltd., True International Communication Co Ltd., BFKT
          (Thailand) Co Ltd., Clearing House for Number Portability Co Ltd.,
          True Vision Group Co Ltd., TrueVisions Public Company Limited,
          Cineplex Co Ltd., True Visions Cable Public Company Limited, Satellite
          Service Co Ltd., Panther Entertainment Co Ltd., AP&J Production Co
          Ltd., True Media Solutions Co Ltd., True United Football Club Co Ltd.,
          SM True Co Ltd., Thai News Network (TNN) Co Ltd., True4You Station Co
          Ltd., True CJ Creation Co Ltd., True GC Co Ltd., True Touch Co Ltd.,
          Asia Wireless Communication Co Ltd., True Voice Co Ltd., True
          Multimedia Co Ltd., True Internet Corporation Co Ltd., True Life Plus
          Co Ltd., True Axion Interactive Co Ltd., Internet Knowledge Service
          Center Co Ltd., KSC Commercial Internet Co Ltd., True Internet Gateway
          Co Ltd., True E-Logistics Co Ltd., True Icontent Co Ltd., True Digital
          Park Co Ltd., True Internet Technology (Shanghai) Co Ltd, True Digital
          Group Co Ltd. (formerly named as “True Digital and Media Platform Co
          Ltd.”), Crave Interactive Limited, Crave Interactive B.V., Crave
          Interactive Inc, PT True Digital Indonesia, True Digital Philippines
          Inc., True Digital Vietnam Joint Stock Company, Telecom Holding Co
          Ltd., Bangkok Inter Teletech Public Company Limited, K.I.N. (Thailand)
          Co Ltd. (registered overseas), MKSC World Dot Com Co Ltd., True Incube
          Co Ltd., True Trademark Holdings Company Limited, Gold Palace
          Investments Limited, Golden Light Co Ltd., Goldsky Co Ltd.,
          Transformation Films Co Ltd., Queue Q (Thailand) Co Ltd., True-Kona
          Cayman GP LINE Games-True-Kona Global Fund Limited Partnership, and
          Mediaload Pte. Ltd. (including its subsidiaries i.e. Mediaload
          (Cambodia) Co., Ltd. and Mediaload Myanmar Company Limited).
        </p>
        <p>
          As at present, the Privacy Policy has been last reviewed on 29
          November 2021.
        </p>

        <br />
        <Typography variant="h4" mb={2} fontWeight={"bolder"}>
          Cookies
        </Typography>
        <h4>Consent cookies</h4>
        <p>
          This website uses cookies. We want you to have a good experience using
          our website. We therefore use cookies that record visits. and the
          usage on the website from your machine to analyze to increase
          efficiency including presenting deals and content that is more
          relevant to you
        </p>
        <h4>Announcement</h4>
        <p>
          Cookies (Cookies) and the use of cookies We may collect, use and
          disclose cookies and similar technologies. When you use our services
          and products This includes access to our websites and applications.
          For the following purposes
        </p>
        <ul>
          <li>Protects you from fraud and continues to improve security.</li>
          <li>
            Study how people visit our websites and apps and our other services
            so that we can make appropriate improvements.
          </li>
          <li>
            Decide which of our products, services and offers may be relevant to
            you
          </li>
          <li>
            Personalize the marketing you see on social media, apps and other
            websites.
          </li>
        </ul>
        <p>
          You can refuse the collection of information via cookies through your
          browser settings. However, these settings may reduce the optimum
          performance of websites and applications.
        </p>
        <br />
        <Typography variant="h4" mb={2} fontWeight={"bolder"}>
          Terms and Conditions for Using Adtech Platform Services
        </Typography>
        <p>
          Welcome to Adtech's services. You can access Adtech's services by
          registering using your email, Facebook or mobile phone number, and
          setting your own password (“User Account”). Once you have registered
          with True Digital Group Company Limited (“Company”, “We”), you will be
          able to log into your User Account to access Adtech immediately.
        </p>
        <p>
          Once you have received your account, you will be able to log in to
          access all of the Company's services currently available and those
          that may be added in the future by the Company and the Group.
          (Hereinafter collectively referred to as “Adtech”) You must comply
          with the following terms and conditions.
        </p>
        <h4>1. User Account Acquisition and Storage</h4>

        <ul>
          <li>
            You must register via Website: https://www.Adtech.net or any other
            channels that the company may additionally specify. If there is a
            change in the said telephone number, the company will inform you
            later.
          </li>
          <li>
            If you cannot remember your user account password or you have lost
            your user account password, you can change your user account
            password yourself through the website.
          </li>
          <li>
            You must keep your user account confidential and do not allow anyone
            to use your user account under any circumstances. You cannot
            transfer your rights or transfer ownership of your user account to
            any person at all. You will be responsible for every action and
            every transaction performed on your user account. This includes
            actions related to payments, infringement of the intellectual
            property rights of the Company and/or Group of Companies, or
            dishonest acts in any property of the company and/or group of
            companies unless you can clearly prove that any such action is not
            the action of you, the real user account owner.
          </li>
          <li>
            If you know or have reason to know that your user account has been
            stolen, you must notify the Company as soon as possible, and we will
            proceed to assist you as quickly as possible.
          </li>
        </ul>
        <h4>2. Qualifications of Adtech Users</h4>
        <ul>
          <li>You must register to get a user account as per point 1.</li>
          <li>
            In using any part of Adtech's service, a service fee must be paid to
            access the service. You must be 20 years of age or older.
          </li>
        </ul>
        <h4>3. Using Adtech</h4>
        <ul>
          <li>
            You can access Adtech through channels, including the Adtech Website
            or other additional channels provided by the company. You will
            receive various benefits. You can check the benefits at Website:
            www.Adtech.ne. You acknowledge that the company has the right
          </li>
          <li>
            edit or change any benefits at any time. You can check the amendment
            or change of benefits from the Website or the channels above.
          </li>
          <li>
            You can use various services on Adtech, provided you do not
            duplicate, transfer, or do anything that is prohibited by law or
            contrary to the law, or contrary to public order and good morals, or
            causing damage or affecting or violating any of the Company's legal
            rights.
          </li>
          <li>
            You agree that you will not copy, republish, duplicate, record,
            transmit, publicly display or display, reverse engineer, decompile,
            disassemble, modify, interfere with, or infringe any intellectual
            property rights. It is strictly prohibited to engage in any illegal
            acts or acts against Adtech's services or content, in whole or in
            part. If you violate the said terms, the company would like to
            inform you that the company will take legal action against you until
            the end.
          </li>
          <li>
            You are prohibited from doing anything with the intention of
            dishonestly dealing with Adtech or any part of Adtech, or
            dishonestly seeking any benefit for oneself from Adtech or any part
            of Adtech, whether intentional or unintentional. If the company sees
            or considers that your actions are fraudulent, causing the company
            to suffer damage, whether directly or indirectly, you may be
            contacted by the company for additional information. And the Company
            has the sole discretion to suspend your user account and take legal
            action against you.
          </li>
          <li>
            If you do not comply with any of the terms and conditions for using
            Adtech services, or the Company deems that your use of Adtech may
            cause damage to the Company, whether directly or indirectly, and for
            whatever reason, the Company has the right to suspend and/or cancel
            your user account immediately without notifying you in advance, and
            you accept that the Company's decision is final and will not make
            any objections.
          </li>
        </ul>
        <h4>4. Use of Your Information</h4>
        <p>
          During your use of Adtech services, you agree and acknowledge that the
          Company may collect, use or disclose information about your Adtech
          usage, including but not limited to: Information about viewing content
          on Adtech. Information about payment for goods or services. History of
          your use of Adtech services in various services and other information
          resulting from your use of Adtech (“User information”). The Company
          will use such information of your users for the purpose of Advertise
          and offer products or services that are beneficial to you. and/or use
          it to develop the company's services and products, as well as use it
          in various parts of the company's business, including the Analytics
          Business, the IOT and Digital Solutions Business, and the IT Service
          Business. Study (Digital Academy Business), entertainment business
          through online platforms (Digital Media Platform Business), customer
          relationship management system business (O2O and Privilege Business)
          or other businesses of the company or group of companies that
          currently exist or that may exist. up in the future
        </p>
        <h4>5. Service Fees and Payment of Services</h4>
        <ul>
          <li>
            Some services on Adtech may charge a fee. You must pay the said
            service fee in order to be able to use that service. The company
            will notify you first every time if any of our services charge a
            service fee.
          </li>
          <li>
            You must pay the service fee to access the Company's services (if
            any) only according to the channels and methods specified by the
            Company.
          </li>
          <li>
            If you agree to pay the service fee to access any of the company's
            services. You agree and acknowledge that you cannot request a refund
            of the said amount. or exchange payment for services already paid
            for another service or transfer services that have already been paid
            to another user's account in any case
          </li>
        </ul>
        <h4>6. Intellectual Property Rights</h4>
        <p>
          You acknowledge that the Company is the owner of the copyright or
          intellectual property rights, or that the Company has received rights
          and has legal rights in the copyright or intellectual property rights
          from the owner of the copyright or intellectual property rights. on
          assets appearing on Adtech, including but not limited to software,
          articles, images, and other content.
        </p>
        <h4>7. Restrictions on Adtech Use</h4>
        <p>
          You must use any services on Adtech only according to the terms and
          conditions set by the company. The Company will inform you of the
          terms and conditions for various services and the Company may change
          such terms and conditions from time to time by notifying you in
          advance.
        </p>
        <h4>8. Indemnity</h4>
        <p>
          If you are negligent or intend to use any part of Adtech that causes
          damage to the image, reputation or any damage to the company and/or
          group of companies. You agree and agree to compensate the Company
          and/or the Company Group for all damages incurred as determined and
          notified by the Company.
        </p>
        <h4>9. Limitation of Company's Liability</h4>
        <p>
          You acknowledge and agree that if you use any of Adtech's services and
          there is any loss or damage to your image, reputation, property or any
          damage caused by your careless, negligent or intentional actions or
          omissions. or of any person or force majeure or any event beyond the
          control of the Company. The company will not be responsible for any
          damages that occur to you.
        </p>
        <h4>10. Other Requirements</h4>
        <ul>
          <li>
            These terms and conditions of use of Adtech services shall be
            interpreted and enforced in accordance with Thai law.
          </li>
          <li>
            In the event that any agreement in these Terms and Conditions of Use
            of Adtech Services is not effective. or cannot be forced The
            remaining agreements under these Adtech Terms and Conditions of Use
            remain in effect and enforceable as set forth.
          </li>
          <li>
            The Company may change any content in these terms and conditions of
            use of Adtech services from time to time by notifying you or
            announcing on the Company's channels.
          </li>
        </ul>
        <p>
          You have read and understood all of the above terms and conditions.
          and you agree to abide by the above terms and conditions in all
          respects.
        </p>
        <h4>Terms and conditions for use of features</h4>

        <h4>1. Your Access to Services</h4>
        <p>
          Children under 13 years of age are not permitted to create an account
          or use the Services. Additionally, you must be over the legal age in
          your country to create an account or use the Services. Or you must
          send us verifiable consent from your parent or legal guardian.
        </p>
        <p>
          In addition, for some services or parts of our services. You must be
          18 years of age or older to use this service, so please read all
          notices and any additional terms carefully when using the Service if
          you agree to the following terms and conditions (“Terms”). of another
          juristic person This would include business entities or government
          agencies. It means that you certify that you have full legal authority
          to enforce this provision against such entity.
        </p>
        <h4>2. Your Use of the Service</h4>
        <p>
          Adtech will permit you to use and access the Service only as permitted
          by these Terms. Such permission will be in the form of personal
          permission. It is non-transferable, non-exclusive, revocable and
          subject to restrictions. We reserve the right not to expressly grant
          you all rights under these Terms. This grant to you does not expressly
          include the following rights: Except in cases where permission is
          given through the provision of services or permission has been given
          by us in writing.
        </p>
        <ul>
          <li>
            License, sell, transfer, give, distribute, make available, or
            otherwise commercially exploit the Services or Content.
          </li>
          <li>
            Modify, create derivative works of, disassemble, decompile, or
            reverse engineer any part of the Service or the Content, or
          </li>
          <li>
            Access the Services or Content in order to create a website. Similar
            or competing products or services
          </li>
        </ul>
        <p>
          We reserve the right to modify, suspend or discontinue the Service.
          (whether in whole or in part) at any time and whether or not notified
          to you, any release, update or other enhancement to the Service will
          be subject to these Terms, which may be updated. Occasionally You
          agree that we will not be liable to you or any third party for any
          modification, suspension, or discontinuance of the Service. or any
          part of the service
        </p>
        <h4>3. Your Adtech Account and User Account Security</h4>
        <p>
          In the event that you will use certain features of our Services. You
          may be required to create an Adtech account (“Account”) and will be
          required to provide you with a username, password and certain
          information about you as set forth in our Privacy Policy. You will be
          responsible for the information associated with your Account. your
          account, including anything that happens in connection with your
          account You must maintain the security of your account and must notify
          Adtech immediately if you discover or suspect that someone has gained
          unauthorized access to your account. We recommend that you use a
          strong password that is used only for your account and allow for
          two-factor authentication. You will not license, sell, or otherwise
          transfer your account. with others without our prior written approval.
        </p>
        <h4>4. Your Consent</h4>
        <p>
          The services provided may contain information, text, links, graphics,
          photographs, animations. or other media (“Content”) appears, including
          content that you create or submit to the Service yourself or through
          your account. (“Your Content”) We are not responsible for, and we do
          not guarantee, support, or guarantee the completeness, truthfulness,
          accuracy, or reliability of any part of your Content. and whether
          express or implied, when submitting your content to the service You
          represent and warrant that you have all rights, power, and authority
          necessary to grant the rights to your Content as provided in these
          Terms. And because you are solely responsible for your content. It is
          possible that you may be held liable if you post or share content
          without all necessary rights. You will retain all proprietary rights
          in your content. But you will allow Adtech to use such content. Under
          the following conditions
        </p>
        <p>
          When your content is created with the Service or submitted to the
          Service. You grant your rights in a format that can be used worldwide.
          No royalties There is no deadline. Irrevocable, non-exclusive,
          transferable to others. and can sublicense the use, copy, modify,
          create derivative works from, distribute, store, perform and display
          your content including name Username, voice, or something similar
          which appears in your content in all media formats and channels which
          is known today or later developed anywhere in the world This license
          includes the right we receive to provide your content for broadcast,
          publication, or publication by other companies, organizations, or
          individuals. which is a partner with Adtech. You also agree that we
          may delete metadata associated with your content. and you hereby waive
          the right to demand and claim any moral rights. or claim ownership of
          your content
        </p>
        <p>
          Ideas, suggestions and feedback about Adtech or our services. What you
          have informed us is considered to be entirely voluntary. and you
          accept that Adtech secretly uses such ideas, suggestions, and feedback
          without any compensation or obligation. to you, although we have no
          obligation to screen, edit, or monitor your content; But we may, in
          our sole discretion, remove or dispose of your content at any time and
          for any reason. Including violations of these terms.
        </p>
        <p>
          Violations of our content policies or in the case where you cause, or
          it is likely that you will cause us to be liable.
        </p>
        <h4>5. Third Party Content, Advertising, and Promotions</h4>
        <p>
          The Services may contain links to third-party websites, products, or
          services. which are posted by our affiliate advertisers, partners or
          other users. (“Third Party Content”). Third Party Content is not under
          our control. And we are not responsible for the product website. or
          any service of outsiders If you use Third Party Content, you do so at
          your own risk and you should investigate any such third party content.
          as you feel appropriate before proceeding with any transaction which
          is related to the content of such third parties in addition The
          Service may contain content.
        </p>
        <p>
          Third party or sponsored advertisements appear. The type, level, and
          target of advertisements can be changed and you acknowledge and agree
          that we may display advertisements in conjunction with the display of
          any content or information on the Service. Including your content.
        </p>
        <p>
          If you choose to use the service to promote sales. Including contests
          or sweepstakes. (“Promotion”) You are responsible for conducting the
          Promotion in compliance with all applicable laws and regulations and
          assume your sole risk. Your promotion must state that the promotion is
          not sponsored, endorsed, or affiliated with Adtech. and the rules
          applicable to your promotion shall require each viewer or participant
          to release Adtech from any liability. which is related to sales
          promotion
        </p>
        <h4>6. Things You Must Not Do</h4>
        <p>
          When using or accessing Adtech, you must comply with these Terms and
          all applicable laws, rules, and regulations, in addition to the
          prohibitions set forth in the Content Policy. You may not do any of
          the following actions.
        </p>
        <ul>
          <li>
            Use the Service in any manner that is likely to disturb, disable,
            cause disruption, or increase burden. or create defects in the
            service
          </li>
          <li>
            Access (or attempt to access) another user's account or any portion
            of the Service that is not publicly available. Including computer
            systems or networks connected or used in connection with the
            Service.
          </li>
          <li>
            Upload, transmit or spread viruses, worms, malicious code. or other
            software to or through the Service for the purpose of interfering
            with the Service or its security-related features;
          </li>
          <li>
            Use the Services in order to violate any applicable law or infringe
            the intellectual property rights or other proprietary rights of any
            person or entity.
          </li>
          <li>
            Access, search, or collect information from the Service by any
            means. (automatic or other means) unless permitted by these Terms.
          </li>
          <li>
            Use the Services in any manner that we reasonably believe is
            harassment or fraud against Adtech or any payment system.
          </li>
        </ul>
        <p>
          We encourage you to report content or behavior that you believe
          violates these Terms or our content policies. We also encourage
          responsible reporting of information about security vulnerabilities.
        </p>
        <h4>7. Copyright Trademark and Deletion of Content</h4>
        <p>
          Adtech respects the intellectual property of others and requires users
          to do the same. We have a policy which includes the removal of any
          material. who infringe copyright will be removed from the service and
          in order to stop inappropriate actions by users who continue to
          infringe If you believe that anything on our Service infringes a
          copyright or trademark that you own or under your control, You may
          notify Adtech via https://www.Adtech.net/
        </p>
        <p>
          Additionally, please be aware that if you knowingly provide false
          information about copyright infringement of any activity or material
          appearing on our service. You may be liable to pay certain costs and
          damages to Adtech.
        </p>
        <h4>8. Indemnity</h4>
        <p>
          Except in cases prohibited by law. You agree to defend, indemnify, and
          defend us and our directors. staff staff Affiliated companies, agents,
          contractors, external service providers and our licensors (“Adtech
          Entities”) from any claims or demands. This will include costs and
          attorney's fees. from any third party due to or arising out of (a)
          your use of the Service; (b) your violation of these Terms; (c) your
          violation of any applicable law or regulation; or (d) your content We
          reserve the right to control the defense of any matter. that you must
          compensate us and you agree to cooperate with us in defending these
          complaints.
        </p>
        <h4>9. LIMITATION OF LIABILITY</h4>
        <p>
          We will provide the best possible service and provide clear guidance
          to everyone who uses our services. However, we provide our services on
          an “as is” basis and do not guarantee that our services will be
          non-destructive, secure, or error-free. We cannot predict when our
          services will fail. Therefore, our liability will be limited to an
          amount not exceeding THB per event. Again, we will not be liable for
          any loss of profits, revenue or data which: This will include
          consequential damages as well.
        </p>
        <h4>10. Changes to these terms</h4>
        <p>
          We may change these terms from time to time. In the event that we
          change the terms We will, at our discretion, post the revised Terms
          and the revised effective date above. If the change is a significant
          change We may notify you by sending an email to the email address
          associated with your account. (if you choose to provide us with your
          email address) or post announcements through our Services If you
          continue to access or use the Services on the effective date of the
          revised Terms or thereafter, This means that you agree to be bound by
          the modified Terms. If you do not agree with the amended terms. You
          must stop accessing and using our Services before the Effective Date.
        </p>
        <h4>11. Additional terms</h4>
        <p>
          This is because of the variety of services we provide. You may be
          asked to agree to additional terms before using any of the products or
          services offered by Adtech. (“Additional Terms”). To the extent that
          the Additional Terms conflict with these Terms, Then bring those
          additional terms to apply to your use of the relevant services.
        </p>
        <h4>12. Cancellation</h4>
        <p>
          We may suspend or terminate your user account. The situation is in
          your control. or your ability to access or use the Service at any time
          and for any reason. or without any reason This includes violations of
          these Terms or our Content Policy. The following sections will survive
          termination of these Terms or termination of your account. - Section 4
          (Your Content), Section 6 (What You Must Not Do), Section 8
          (Indemnification), Section 9 (Limitation of Liability), Section 12
          (Termination), and Section 13 (Miscellaneous Provisions). )
        </p>
        <h4>13. Miscellaneous provisions</h4>
        <p>
          These Terms constitute the entire agreement between you and us
          relating to your access to and use of the Service. Our failure to
          exercise or enforce any rights or regulations. of these provisions
          shall not be considered a waiver or concession to such provisions. In
          the event that for any reason any provision contained in these Terms
          is determined to be unlawful, void or unenforceable, The remaining
          provisions of these Terms will remain in full force and effect.
          Additionally, you may not assign or transfer any rights or
          obligations. your information under these Terms to others without our
          consent We may also transfer any rights and obligations. of oneself
          under these terms can be given to others without the need to obtain
          consent from anyone.
        </p>
        <br />
      </div>
    </div>
  );
}

export default Terms;
