import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { List, ListItem } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../Components/navbar';
import APIS from './APIS'

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [formStatus, setFormStatus] = useState({
    success: null,
    error: null,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email'); 
  const code = queryParams.get('code'); 

  const [passwordData, setPasswordData] = useState({
    password: '',
    confirmPassword: '',
    email: email
  });

  const [otpData, setOtpData] = useState({
    code: code
  });

  const [validationErrors, setValidationErrors] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleContinue = () => {
    // Reset validation errors
    setValidationErrors({
      password: '',
      confirmPassword: '',
    });

    // Validate required fields
    if (!passwordData.password) {
      setValidationErrors({
        ...validationErrors,
        password: 'New Password is required.',
      });
      return;
    }

    // Validate password complexity
    const complexityRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;

    if (!complexityRegex.test(passwordData.password)) {
      setValidationErrors({
        ...validationErrors,
        password: 'Password must contain at least 1 alphabet, 1 digit, and 1 special character.',
      });
      return;
    }

    if (!passwordData.confirmPassword) {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: 'Confirm New Password is required.',
      });
      return;
    }

    // Validate password matching
    if (passwordData.password !== passwordData.confirmPassword) {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: 'Passwords do not match.',
      });
      return;
    }

    setIsButtonDisabled(true); // Disable the button on click

    // First, verify the OTP
    APIS.VerifyOTP(otpData)
      .then((verifyOtpResponse) => {
        if (verifyOtpResponse.code == 200) {
          // If OTP verification is successful, proceed to reset the password
          return APIS.ResetPassword(passwordData);
        } else {
          // OTP verification failed
          throw new Error(verifyOtpResponse.message || 'OTP verification failed.');
        }
      })
      .then((resetPasswordResponse) => {
        if (resetPasswordResponse.code == 200) {
          // Password reset successful
          setFormStatus({ success: resetPasswordResponse.message, error: null });
          navigate('/Login');
        } else {
          // Password reset failed
          throw new Error(resetPasswordResponse.message || 'Password reset failed.');
        }
      })
      .catch((error) => {
        // Error occurred during the process
        setFormStatus({ success: null, error: error.message || 'An error occurred.' });
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  return (
    <div className='login-card'>
      <Navbar />
      <h2>Forgot Password</h2>

      {formStatus.success && (
        <div className="success-message">{formStatus.success}</div>
      )}

      {formStatus.error && (
        <div className="error-message">{formStatus.error}</div>
      )}

      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            label="New Password"
            placeholder='Enter new password'
            InputLabelProps={{ shrink: true }}
            variant="standard"
            name="password"
            type="password"
            onChange={handleChange}
            required
            error={!!validationErrors.password}
            helperText={validationErrors.password}
          />
          <div className='info-sec'>
            Please make sure your password contains at least 1 character from all of the following
            <List
              sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
                p: 0,
                m: 0
              }}
            >
              <ListItem sx={{ display: 'list-item' }}>
                Alphabet
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                Lower and upper case
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                Special character
              </ListItem>
            </List>
          </div>
          <div>
            <TextField
              label="Confirm New Password"
              placeholder='Enter confirm new password'
              InputLabelProps={{ shrink: true }}
              variant="standard"
              name="confirmPassword"
              type="password"
              onChange={handleChange}
              required
              error={!!validationErrors.confirmPassword}
              helperText={validationErrors.confirmPassword}
            />
          </div>

          <Button variant="contained" size="medium" onClick={handleContinue} disabled={isButtonDisabled}>
            CONTINUE
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default ForgotPassword;
