import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  TablePagination,
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import { MoreVert, Search, Visibility } from "@mui/icons-material";
import Navbar from "../Components/navbar";
import Sidebar from "../Components/sidebar";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import { rowsPerPageOptions } from "../helper/enum";
import AudienceStatusDialog from "../Components/AudienceStatusDialog";

const AudienceList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [audiences, setAudiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedAudience, setSelectedAudience] = useState("");
  const [audienceId, setAudienceId] = useState("");

  useEffect(() => {
    fetchAudience();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fetchAudience = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const token = localStorage.getItem("token");
      const response = await APIS.getAudienceList(token);
      setAudiences(response.audiences ?? []); // Update projects state with fetched data
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);
  };

  const filteredAudiences = () => {
    if (searchTerm)
      return audiences.filter((element) =>
        element.audienceName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    return audiences;
  };

  const navigateAudiencePush = () => {
    navigate("/AudiencePerformPush/" + audienceId);
  };

  return (
    <div className="inner-wrapper">
      <Grid container>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={1}>
          <Sidebar />
        </Grid>
        <Grid item xs={11}>
          <div className="content-wrapper">
            <h2>Audience</h2>

            <div className="sub-title">Description</div>
            <div className="search-card">
              <Box
                sx={{
                  width: "60%",
                  display: "inline-flex",
                }}
              >
                <TextField
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{ width: "60%" }}
                  variant="standard"
                  placeholder="Search by Audience Name, Created By"
                  InputProps={{
                    startAdornment: <Search />,
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "40%",
                  display: "inline-flex",
                }}
              ></Box>
            </div>
            {loading ? ( // Show loader if loading is true
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>

                      <TableCell>Audience Name</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Updated Date</TableCell>
                      <TableCell>Created by</TableCell>
                      <TableCell>Audience Size</TableCell>
                      <TableCell>History</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredAudiences()
                      ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((aud) => (
                        <TableRow key={aud.id}>
                          <TableCell>
                            <input
                              type="checkbox"
                              value={aud.id}
                              // checked={checkedCheckboxes.includes(project.id)}
                              // onChange={handleCheckboxChange}
                            />
                          </TableCell>
                          <TableCell>{aud.audienceName}</TableCell>
                          <TableCell>
                            {formatDate(aud.createdDate?.value)}
                          </TableCell>
                          <TableCell>
                            {formatDate(aud.updatedDate?.value ?? null)}
                          </TableCell>
                          <TableCell>{aud.createdBy}</TableCell>
                          <TableCell>{aud.audienceSize}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => setSelectedAudience(aud)}
                            >
                              <Visibility />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Button
                              id="basic-button"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setAudienceId(aud.id);
                                setOpen(true);
                              }}
                            >
                              <MoreVert />
                            </Button>

                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={() => setOpen(false)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  navigateAudiencePush(aud.id);
                                }}
                              >
                                Perform Audience Push
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <TablePagination
              component="div"
              count={filteredAudiences()?.length ?? 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
      {selectedAudience && (
        <AudienceStatusDialog
          selectedAudience={selectedAudience}
          closeAudience={() => setSelectedAudience("")}
        />
      )}
    </div>
  );
};

export default AudienceList;
