import React, { useState } from "react";

const AppLogo = ({ onClick, style }) => {
  return (
    <div className="header-logo " style={style} onClick={onClick}>
      <span className="c-black">AD</span>
      <span className="c-red">TECH+</span>
    </div>
  );
};

export default AppLogo;
