import React from "react";
import { Link, useLocation } from "react-router-dom";

function Sidebar() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="side-menu">
      <div className="top-menu">
        {/* <div className={`menu ${isActive("/ProjectList") ? "active" : ""}`}>
          <Link to="/ProjectList">
            <div>
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 2H10L8 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V4C20 2.89 19.11 2 18 2ZM18 14H2V2H7.17L9.17 4H18V14ZM10 10H12V12H14V10H16V8H14V6H12V8H10V10Z"
                  fill="#323232"
                />
              </svg>
            </div>
            Projects
          </Link>
        </div> */}
        <div className={`menu ${isActive("/ChannelSetup") ? "active" : ""}`}>
          <Link to="/ChannelSetup">
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4 19.9998L13 18.5998L15.6 15.9998L13 13.3998L14.4 11.9998L17 14.5998L19.6 11.9998L21 13.3998L18.4 15.9998L21 18.5998L19.6 19.9998L17 17.3998L14.4 19.9998ZM16.375 10.9998L12.825 7.4498L14.225 6.0498L16.35 8.1748L20.6 3.9248L22 5.3498L16.375 10.9998ZM2 16.9998V14.9998H11V16.9998H2ZM2 8.9998V6.9998H11V8.9998H2Z"
                  fill="#737373"
                />
              </svg>
            </div>
            Channel
            <br /> Setup
          </Link>
        </div>
        <div
          className={`menu ${
            isActive("/Audience") ||
            isActive("/CreateAudience") ||
            isActive("/AudienceList")
              ? "active"
              : ""
          }`}
        >
          <Link to="/Audience">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 8.00001C10.5 6.35001 9.14999 5.00001 7.49999 5.00001C5.84999 5.00001 4.49999 6.35001 4.49999 8.00001C4.49999 9.65001 5.84999 11 7.49999 11C9.14999 11 10.5 9.65001 10.5 8.00001ZM7.49999 9.00001C6.94999 9.00001 6.49999 8.55001 6.49999 8.00001C6.49999 7.45001 6.94999 7.00001 7.49999 7.00001C8.04999 7.00001 8.49999 7.45001 8.49999 8.00001C8.49999 8.55001 8.04999 9.00001 7.49999 9.00001ZM14 11C15.11 11 16 10.11 16 9.00001C16 7.89001 15.11 7.00001 14 7.00001C12.89 7.00001 11.99 7.89001 12 9.00001C12 10.11 12.89 11 14 11ZM9.98999 0.0100098C4.46999 0.0100098 -0.0100098 4.49001 -0.0100098 10.01C-0.0100098 15.53 4.46999 20.01 9.98999 20.01C15.51 20.01 19.99 15.53 19.99 10.01C19.99 4.49001 15.51 0.0100098 9.98999 0.0100098ZM3.83999 15.12C4.51999 14.58 6.10999 14.01 7.49999 14.01C7.56999 14.01 7.64999 14.02 7.72999 14.02C7.96999 13.38 8.39999 12.73 9.02999 12.16C8.46999 12.06 7.93999 12 7.49999 12C6.19999 12 4.10999 12.45 2.76999 13.43C2.26999 12.39 1.98999 11.23 1.98999 10C1.98999 5.59001 5.57999 2.00001 9.98999 2.00001C14.4 2.00001 17.99 5.59001 17.99 10C17.99 11.2 17.72 12.34 17.24 13.37C16.24 12.78 14.88 12.5 14 12.5C12.48 12.5 9.49999 13.31 9.49999 15.2V17.98C7.22999 17.85 5.20999 16.77 3.83999 15.12Z"
                  fill="#323232"
                />
              </svg>
            </div>
            Audience
            <br /> Segment
          </Link>
        </div>
        {/* <div className={`menu ${isActive("/Campaign") ? "active" : ""}`}>
          <div>
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3C0.9 3 0 3.9 0 5V7C0 8.1 0.9 9 2 9H3V13H5V9H6L11 12V0L6 3H2ZM7.03 4.71L9 3.53V8.47L7.03 7.29L6.55 7H6H2V5H6H6.55L7.03 4.71Z"
                fill="#323232"
              />
            </svg>
          </div>
          <div>Campaign</div>
        </div> */}
      </div>

      <div className="bottom-menu">
        <div
          className={`menu ${
            isActive("/ResetPassword") || isActive("/ResetPassword")
              ? "active"
              : ""
          }`}
        >
          <Link to="/ResetPassword">
            <div>
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 14H16V10H13.32C12.18 12.42 9.72 14 7 14C3.14 14 0 10.86 0 7C0 3.14 3.14 0 7 0C9.72 0 12.17 1.58 13.32 4H24V10H22V14ZM18 12H20V8H22V6H11.94L11.71 5.33C11.01 3.34 9.11 2 7 2C4.24 2 2 4.24 2 7C2 9.76 4.24 12 7 12C9.11 12 11.01 10.66 11.71 8.67L11.94 8H18V12ZM7 10C5.35 10 4 8.65 4 7C4 5.35 5.35 4 7 4C8.65 4 10 5.35 10 7C10 8.65 8.65 10 7 10ZM7 6C6.45 6 6 6.45 6 7C6 7.55 6.45 8 7 8C7.55 8 8 7.55 8 7C8 6.45 7.55 6 7 6Z"
                  fill="#323232"
                />
              </svg>
            </div>
            Change
            <br /> Password
          </Link>
        </div>

        <div
          className={`menu ${
            isActive("/Audience1") || isActive("/CreateAudience1")
              ? "active"
              : ""
          }`}
        >
          <Link to="/Audience">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 8.00001C10.5 6.35001 9.14999 5.00001 7.49999 5.00001C5.84999 5.00001 4.49999 6.35001 4.49999 8.00001C4.49999 9.65001 5.84999 11 7.49999 11C9.14999 11 10.5 9.65001 10.5 8.00001ZM7.49999 9.00001C6.94999 9.00001 6.49999 8.55001 6.49999 8.00001C6.49999 7.45001 6.94999 7.00001 7.49999 7.00001C8.04999 7.00001 8.49999 7.45001 8.49999 8.00001C8.49999 8.55001 8.04999 9.00001 7.49999 9.00001ZM14 11C15.11 11 16 10.11 16 9.00001C16 7.89001 15.11 7.00001 14 7.00001C12.89 7.00001 11.99 7.89001 12 9.00001C12 10.11 12.89 11 14 11ZM9.98999 0.0100098C4.46999 0.0100098 -0.0100098 4.49001 -0.0100098 10.01C-0.0100098 15.53 4.46999 20.01 9.98999 20.01C15.51 20.01 19.99 15.53 19.99 10.01C19.99 4.49001 15.51 0.0100098 9.98999 0.0100098ZM3.83999 15.12C4.51999 14.58 6.10999 14.01 7.49999 14.01C7.56999 14.01 7.64999 14.02 7.72999 14.02C7.96999 13.38 8.39999 12.73 9.02999 12.16C8.46999 12.06 7.93999 12 7.49999 12C6.19999 12 4.10999 12.45 2.76999 13.43C2.26999 12.39 1.98999 11.23 1.98999 10C1.98999 5.59001 5.57999 2.00001 9.98999 2.00001C14.4 2.00001 17.99 5.59001 17.99 10C17.99 11.2 17.72 12.34 17.24 13.37C16.24 12.78 14.88 12.5 14 12.5C12.48 12.5 9.49999 13.31 9.49999 15.2V17.98C7.22999 17.85 5.20999 16.77 3.83999 15.12Z"
                  fill="#323232"
                />
              </svg>
            </div>
            User
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
