import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '../Components/TabPanel';
import Navbar from '../Components/navbar';
import Grid from '@mui/material/Grid';
import Sidebar from '../Components/sidebar';
import TabAudience from './Tab-Audience';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from "react-router-dom";
import TabWebsiteTraffic from './Tab-WebsiteTraffic';
import TabLocationTraffic from './Tab-LocationTraffic';
import APIS from './APIS';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import happywomenimg from '../happy woman jumping with confetti.png'

const CreateAudience = ({ id }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectName = searchParams.get('projectName');


  const navigate = useNavigate();

  const [mainTabValue, setMainTabValue] = useState('aud');
  const [audName, setAudName] = useState('');
  const [showCreateAudForm, setShowCreateAudForm] = useState(false);


  //Search dropdown
  const [openDialog, setOpenDialog] = useState(false);

  const [locationObj, setLocationObj] = useState(null);
  const [loading, setLoading] = useState(true);

  const [demographicsData, setDemographicsData] = useState([]);
  const [loaderDemoData, setLoaderDemoData] = useState(true);
  const [chipsArray, setchipsArray] = useState({});

  const [custInterestData, setCustInterestData] = useState([]);
  const [loaderCustInterestData, setLoaderCustInterestData] = useState(true);

  const [commRelevantData, setCommRelevantData] = useState([]);
  const [loaderCommRelData, setLoaderCommRelData] = useState(true);

  const [locationTrafficData, setLocationTrafficData] = useState([]);
  const [websiteTrafficData, setWebsiteTrafficData] = useState([]);


  //Data Persist in state for all tabs
  const [demographicsselectedFilters, setDemographicsselectedFilters] = useState({});
  const [custInterestselectedFilters, setCustInterestselectedFilters] = useState({});
  //const [geographicsselectedFilters, setGeographicsselectedFilters] = useState({});
  const [commRelevantselectedFilters, setCommRelevantselectedFilters] = useState({});

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [provinceDistrictCount, setProvinceDistrictCount] = useState([]);
  const [regionProvinceCount, setRegionProvinceCount] = useState([]);

  useEffect(() => {
    console.log('demographicsselectedFilters:', demographicsselectedFilters);
    // Simulate an API call or any asynchronous operation
    fetchDemographicData();
    fetchCustomerInterestData();
    fetchCommercialRelevantData();
    getLocationData();
  }, []);

  const getLocationData = async () => {
    setLoading(true);

    try {
      const response = await APIS.getGeographics(localStorage.getItem('token'));
      setLocationObj(response);
      setLoading(false);
    } catch (error) {
      console.log('error:', error);
      setLoading(false);
    }
  }

  const handleDemoDelete = (key, val) => {
    // alert(key);
    //alert('You clicked the delete icon.');
    updateFilters(key, val);
  };

  const showCreateCampaign = () => {
    alert('Yet to developed!!');
  }

  const showAudList = () => {
    navigate('/AudienceList');
  }

  const handleCommRelevantDelete = (datakey, dataval) => {
    const updatedFilters = {};
    for (const key in commRelevantselectedFilters) {
      if (key === datakey && commRelevantselectedFilters[key][0].value === dataval) {
        continue; // Skip adding the entry if the value is 'high' for 'affluence'
      }
      updatedFilters[key] = demographicsselectedFilters[key];
    }
    setCommRelevantselectedFilters(updatedFilters);
  }

  const handleCustomerInterestDelete = (category, subcategory, filterValue) => {
    const updatedFilters = { ...custInterestselectedFilters };

    // Check if the category and subcategory exist
    if (updatedFilters[category] && updatedFilters[category][subcategory]) {
      // Filter out the filter value
      updatedFilters[category][subcategory].filters = updatedFilters[category][subcategory].filters.filter(
        filter => filter.value !== filterValue
      );

      // Set the updated filters
      setCustInterestselectedFilters(updatedFilters);
    }
  }

  const updateFilters = (datakey, dataval) => {
    const updatedFilters = {};
    for (const key in demographicsselectedFilters) {
      if (key === datakey && demographicsselectedFilters[key][0].value === dataval) {
        continue; // Skip adding the entry if the value is 'high' for 'affluence'
      }
      updatedFilters[key] = demographicsselectedFilters[key];
    }
    setDemographicsselectedFilters(updatedFilters);
  };

  const fetchDemographicData = async () => {
    setLoaderDemoData(true);

    try {
      const response = await APIS.getDemographics(localStorage.getItem('token'));
      if (response.code == 200) {
        setDemographicsData(response.data.AudienceFilter.demo);
        console.log('demographicsData:', demographicsData);
        setLoaderDemoData(false);
      }
    } catch (error) {
      console.log('error:', error);
      setLoaderDemoData(false);
    }
  }

  const fetchCustomerInterestData = async () => {
    setLoaderCustInterestData(true);

    try {
      const response = await APIS.getCustomerInterest(localStorage.getItem('token'));
      if (response.code == 200) {
        setCustInterestData(response.data.AudienceFilter.segment);
        console.log('gh:', custInterestData);
        setLoaderCustInterestData(false);
      }
    } catch (error) {
      console.log('error:', error);
      setLoaderCustInterestData(false);
    }
  }

  const fetchCommercialRelevantData = async () => {
    setLoaderCommRelData(true);

    try {
      const response = await APIS.getCommercialRelevant(localStorage.getItem('token'));
      if (response.code == 200) {
        setCommRelevantData(response.data.AudienceFilter.comm_relevance);
        console.log('commdata:', commRelevantData);
        setLoaderCommRelData(false);
      }
    } catch (error) {
      console.log('error:', error);
      setLoaderCommRelData(false);
    }
  }

  const tabStyles = {
    width: '33.33%', // Set the width for each tab
  };

  const handleAudienceName = (event) => {
    setAudName(event.target.value);
  };

  const handleRegionDelete = (currRegion) => {
    setSelectedRegions(selectedRegions.filter(item => item !== currRegion));
  }

  const handleProvinceDelete = (currProvince) => {
    setSelectedProvinces(selectedProvinces.filter(item => item !== currProvince));
  }

  const handleDistrictDelete = (currDistrict) => {
    setSelectedDistricts(selectedDistricts.filter(item => item !== currDistrict));
  }

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const navigateToAudienceList = () => {
    navigate('/Audience')
  };

  const showCreateAudOptions = () => {
    setShowCreateAudForm(false);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const createAudience = async () => {
    const createAudData = {
      "projectId": projectName,
      "audienceSegementName": audName,
      "filters": {
        'demo': transformedData(demographicsselectedFilters),
        "geog": {
          "resident_location_district_en_cat": {
            "filters": selectedDistricts
          },
          "resident_location_province_en_cat": {
            "filters": selectedProvinces
          }
        },
        'comm_relevance': transformedData(commRelevantselectedFilters),
        "locationTraffic": {
          "filters": locationTrafficData
        },
        "websiteTraffic": {
          "filters": websiteTrafficData
        }
      }
    };

    try {
      let response = await APIS.createAudience(createAudData, localStorage.getItem('token'));
      console.log('response:', response);
      if (response.status == 'success') {
        // setLocTrafficCount(response.count);
        setOpenDialog(true);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }


  const transformedData = (data) => {
    const transformedData = {};
    for (const key in data) {
      transformedData[key] = {
        filters: data[key].map(item => ({ value: item.value }))
      };
    }
    return transformedData;
  }

  const showNextTab = () => {
    // Logic to switch to the next tab
    if (mainTabValue === 'aud') {
      setMainTabValue('loc-traffic');
    }

    if (mainTabValue === 'loc-traffic') {
      setMainTabValue('web-traffic');
    }

    if (mainTabValue === 'web-traffic') {
      console.log('cust--', demographicsselectedFilters);
      setShowCreateAudForm(true);
    }
  };


  return (
    <Grid container>
      <Grid item xs={12}><Navbar></Navbar></Grid>
      <Grid item xs={1}><Sidebar></Sidebar></Grid>
      <Grid item xs={11}>{showCreateAudForm}

        {!showCreateAudForm ? (
          <div className='content-wrapper'>
            <h2>Create Audience</h2>
            <div className='sub-title'>To set audience segment</div>

            <Box
              component="form"
              noValidate
              autoComplete="off"
            >

              <div className='back-text' onClick={navigateToAudienceList}><ArrowBackIcon></ArrowBackIcon><span>Back to Audience List</span> </div>
              <div sx={{ margin: '20px 0' }}>Project Name: <b>{projectName}</b></div>
              <h3>Select Type for Traffic Estimation</h3>
              <Tabs value={mainTabValue} onChange={handleMainTabChange} className='tab-sec'>
                <Tab label="Audience" value={'aud'} style={tabStyles} />
                <Tab label="Location Traffic" value={'loc-traffic'} style={tabStyles} />
                <Tab label="Website Traffic" value={'web-traffic'} style={tabStyles} />
              </Tabs>

              <TabPanel value={mainTabValue} index={'aud'}>
                <TabAudience demographicsData={demographicsData} loaderDemoData={loaderDemoData}
                  custInterestData={custInterestData} loaderCustInterestData={loaderCustInterestData}
                  locationObj={locationObj} loading={loading}
                  commRelevantData={commRelevantData} loaderCommRelData={loaderCommRelData}
                  setDemographicsselectedFilters={setDemographicsselectedFilters} demographicsselectedFilters={demographicsselectedFilters}
                  setCustInterestselectedFilters={setCustInterestselectedFilters} custInterestselectedFilters={custInterestselectedFilters}
                  setSelectedRegions={setSelectedRegions} selectedRegions={selectedRegions}
                  setSelectedProvinces={setSelectedProvinces} selectedProvinces={selectedProvinces}
                  setSelectedDistricts={setSelectedDistricts} selectedDistricts={selectedDistricts}
                  setProvinceDistrictCount={setProvinceDistrictCount} provinceDistrictCount={provinceDistrictCount}
                  setRegionProvinceCount={setRegionProvinceCount} regionProvinceCount={regionProvinceCount}
                  setCommRelevantselectedFilters={setCommRelevantselectedFilters} commRelevantselectedFilters={commRelevantselectedFilters}
                  setLocationTrafficData={setLocationTrafficData} locationTrafficData={locationTrafficData}
                  setWebsiteTrafficData={setWebsiteTrafficData} websiteTrafficData={websiteTrafficData}
                  showNextTab={showNextTab}

                />
              </TabPanel>

              <TabPanel value={mainTabValue} index={'loc-traffic'} className="aud-type">
                <TabLocationTraffic handleNextTab={showNextTab}
                  setLocationTrafficData={setLocationTrafficData} locationTrafficData={locationTrafficData}
                  setWebsiteTrafficData={setWebsiteTrafficData} websiteTrafficData={websiteTrafficData}
                />
              </TabPanel>

              <TabPanel value={mainTabValue} index={'web-traffic'} className="aud-type">
                <TabWebsiteTraffic handleNextTab={showNextTab}
                  setLocationTrafficData={setLocationTrafficData} locationTrafficData={locationTrafficData}
                  setWebsiteTrafficData={setWebsiteTrafficData} websiteTrafficData={websiteTrafficData}
                />
              </TabPanel>


            </Box>
          </div>
        ) :
          (
            <div className='content-wrapper'>
              <h2>Create Audience</h2>
              <div className='sub-title'>To set audience segment</div>
              <div>


                <Button variant="contained" size="small" onClick={showCreateAudOptions}>
                  BACK
                </Button>
              </div><br></br>



              <div>
                <TextField
                  label="Audience Segment Name"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  type='text'
                  required
                  placeholder='Audience Segment Name'
                  sx={{ width: '50%' }}
                  value={audName}
                  onChange={handleAudienceName}
                />
              </div><br></br>

              <div><b>Estimated Audience</b></div>

              {Object.keys(demographicsselectedFilters).length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <span><b>Demographics</b></span>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(demographicsselectedFilters).map(([key, value], index) => (


                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', width: '10%', fontSize: '12px' }}><b>{key}</b></div>
                      <div style={{ display: 'flex', width: '90%' }}>
                        {value.map((item, idx) => (
                          <Stack key={idx} direction="row" spacing={1}>
                            <Chip label={item.value}
                              onDelete={() => handleDemoDelete(key, item.value)}
                            />
                          </Stack>
                        ))}
                      </div>
                    </div>


                  ))}

                </AccordionDetails>
              </Accordion>
              )}
              {/*Demographic END */}

              {Object.keys(custInterestselectedFilters).length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="cust-interest-content"
                  id="cust-interest-header"
                >
                  <span><b>Customer Interest</b></span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {Object.entries(custInterestselectedFilters).map(([category, subcategories], index) => (
                      <div key={index}>
                        {/* <div>{category} - </div> */}
                        {Object.entries(subcategories).map(([subcategory, filters], idx) => (
                          <div key={idx}>
                            <div>{category} - {subcategory}</div>
                            {filters.filters.map((filter, idx2) => (
                              // <div key={idx2}>{filter.value}</div>
                              <Chip style={{ fontSize: '10px' }} key={index} label={filter.value} onDelete={() => handleCustomerInterestDelete(category, subcategory, filter.value)} />
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              )}

              {(selectedRegions.length > 0 || selectedProvinces.length > 0 || selectedDistricts.length > 0) && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="geographic-content"
                  id="geographic-header"
                >
                  <span><b>Geographics</b></span>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', width: '10%', fontSize: '12px' }}><b>Region</b></div>
                    <div style={{ display: 'flex', width: '90%' }}>
                      <Stack direction="row" spacing={1}>
                        {selectedRegions.map((value, index) => (
                          <Chip style={{ fontSize: '10px' }} key={index} label={value} onDelete={() => handleRegionDelete(value)} />
                        ))}
                      </Stack>
                    </div>
                  </div>

                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', width: '10%', fontSize: '12px' }}><b>Province</b></div>
                    <div style={{ display: 'flex', width: '90%' }}>
                      <Stack direction="row" spacing={1}>
                        {selectedProvinces.map((value, index) => (
                          <Chip style={{ fontSize: '10px' }} key={index} label={value} onDelete={() => handleProvinceDelete(value)} />
                        ))}
                      </Stack>
                    </div>
                  </div>

                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', width: '10%', fontSize: '12px' }}><b>District</b></div>
                    <div style={{ display: 'flex', width: '90%' }}>
                      <Stack direction="row" spacing={1}>
                        {selectedDistricts.map((value, index) => (
                          <Chip style={{ fontSize: '10px' }} key={index} label={value} onDelete={() => handleDistrictDelete(value)} />
                        ))}
                      </Stack>
                    </div>
                  </div>

                </AccordionDetails>
              </Accordion>
              )}

              {Object.keys(commRelevantselectedFilters).length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="comm-rel-content"
                  id="comm-rel-header"
                >
                  <span><b>Commercial Relevant</b></span>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(commRelevantselectedFilters).map(([key, value], index) => (


                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', width: '10%', fontSize: '12px' }}><b>{key}</b></div>
                      <div style={{ display: 'flex', width: '90%' }}>
                        {value.map((item, idx) => (
                          <Stack key={idx} direction="row" spacing={1}>
                            <Chip label={item.value}
                              onDelete={() => handleCommRelevantDelete(key, item.value)}
                            />
                          </Stack>
                        ))}
                      </div>
                    </div>
                  ))}

                </AccordionDetails>
              </Accordion>
              )}

              {/* <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="loc-traffic-content"
                  id="loc-traffic-header"
                >
                <span><b>Location Traffic</b></span>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(locationTrafficData).map(([key, value], index) => (


                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', width: '10%', fontSize: '12px' }}><b>{key}</b></div>
                      <div style={{ display: 'flex', width: '90%' }}>
                        {value.map((item, idx) => (
                          <Stack key={idx} direction="row" spacing={1}>
                            <Chip label={item.value}
                              onDelete={() => handleCommRelevantDelete(key, item.value)}
                            />
                          </Stack>
                        ))}
                      </div>
                    </div>
                  ))}

                </AccordionDetails>
              </Accordion> */}

              <br></br><div>
                <Button variant="contained" size="small" onClick={(event) => createAudience()}>
                  CREATE
                </Button>
              </div>


              <Dialog open={openDialog} onClose={handleCloseDialog} className='modal-delete'>
                <img src={happywomenimg} width='90' height='200' style={{ margin: 'auto' }} />
                <DialogTitle className='modal-lg-text'>Successfully Created!</DialogTitle>
                <DialogContent className='modal-sm-text'>You have successfully create your Audience Segment. You can choose to view your Audience List or continue to Campaign Creation.</DialogContent>

                <DialogActions>
                  <Button onClick={showAudList}>AUDIENCE LIST</Button>
                  <Button onClick={showCreateCampaign} variant="contained" color="primary">
                    CREATE CAMPAIGN
                  </Button>
                </DialogActions>



              </Dialog>
            </div>


          )
        }
      </Grid>
    </Grid>
  );
};

export default CreateAudience;
