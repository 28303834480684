import React from "react";
import GoogleLogo from "../Assets/logo_google.svg";
import MetaLogo from "../Assets/logo_meta.svg";
import TiktokLogo from "../Assets/logo_tiktok.svg";
import LineLogo from "../Assets/logo_line.svg";

const ChannelLogoLable = ({ channelName }) => {
  const getChannelLogo = () => {
    if (channelName === "Google") {
      return <img src={GoogleLogo} alt="Google" className="mr-1" />;
    } else if (channelName === "Meta") {
      return <img src={MetaLogo} alt="Meta" className="mr-1" />;
    } else if (channelName === "Tiktok") {
      return <img src={TiktokLogo} alt="Meta" className="mr-1" />;
    } else if (channelName === "Line") {
      return <img src={LineLogo} alt="Meta" className="mr-1" />;
    }
  };
  return (
    <div className="channelLogo">
      <span>{getChannelLogo()}</span>
      <span>{channelName}</span>
    </div>
  );
};

export default ChannelLogoLable;
