import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import HelpIcon from '@mui/icons-material/Help';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import APIS from './APIS';
import AudienceFooter from './AudienceFooter';

const CustomerInterest = ({ custInterestData, custInterestselectedFilters, setCustInterestselectedFilters,handleNextTab }) => {
  const [mainTabValue, setMainTabValue] = useState(0);
  const [startIndex, setStartIndex] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [demoCount, setDemoCount] = useState(0);
  const [conjunctionType, setConjunctionType] = useState('AND'); // Default to 'AND'


  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };


  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const setConjunction = (type) => {
    setConjunctionType(type);
  };

  const filteredCategories = Object.keys(custInterestData).filter(category => {
    // Check if the category matches the search value
    if (category.toLowerCase().includes(searchValue.toLowerCase())) {
      return true;
    }

    // Check if any subcategory in this category matches the search value
    const subcategories = Object.keys(custInterestData[category]);
    for (const subcategory of subcategories) {
      if (subcategory.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
    }

    return false;
  });


  const handleNext = () => {
    setStartIndex(prevIndex => prevIndex + 3);
  };

  const handlePrev = () => {
    setStartIndex(prevIndex => Math.max(prevIndex - 3, 0));
  };

  const removeEmptyValues = obj => {
    return Object.keys(obj).reduce((acc, key) => {
      // Check if the value is not an empty array and not null/undefined
      if (obj[key] && (Array.isArray(obj[key]) && obj[key].length !== 0)) {
        // If the condition is met, add the key-value pair to the accumulator object
        acc[key] = obj[key];
      }
      // Return the accumulator object for the next iteration
      return acc;
    }, {});
  };

  const handleCalculate = async () => {
    console.log('custInterestselectedFilters:',custInterestselectedFilters);
console.log(conjunctionType);
    const filterData = {};
    // Iterate over the original data
    // for (const [key, value] of Object.entries(custInterestselectedFilters)) {
    //   console.log('ni', value);
    //   // Create a new object with the desired structure
    //   filterData[key] = {
    //     filters: value.map(item => ({ value: item }))
    //   };
    // }

  // const finalObj = {segment: custInterestselectedFilters};

   // console.log('finalObjfinalObj:', finalObj);

    const finalObj = {
      segment: {
        operation: conjunctionType, // Convert selectedPeriod to integer if necessary
        interests: custInterestselectedFilters
      }
    };

    try {
      let response = await APIS.calculateCountCustomerInterest(finalObj, localStorage.getItem('token'));
      console.log('response:' ,response);
      if (response.code == 200) {
        setDemoCount(response.count);
      } 
    } catch (error) {
      console.log('Error:' ,error);
    } 
  };

  const toggleFilter1 = (category, subcategory, value) => {
    setCustInterestselectedFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      if (!updatedFilters[category + '__' + subcategory]) {
        updatedFilters[category + '__' + subcategory] = [value]; // Initialize with the checked value
      } else {
        // Toggle the filter
        updatedFilters[category + '__' + subcategory] = updatedFilters[category + '__' + subcategory].includes(value)
          ? updatedFilters[category + '__' + subcategory].filter(filter => filter !== value)
          : [...updatedFilters[category + '__' + subcategory], value];
      }

      // setCustInterestselectedFilters(updatedFilters);
      console.log(removeEmptyValues(updatedFilters));
      return removeEmptyValues(updatedFilters);
    });
  };

  const toggleFilter = (category, subcategory, value) => {
    setCustInterestselectedFilters(prevFilters => {
        // Initialize updatedFilters as a copy of prevFilters
        const updatedFilters = { ...prevFilters };

        // Check if the category exists in updatedFilters
        if (!updatedFilters[category]) {
            updatedFilters[category] = {}; // If not, initialize it as an empty object
        }

        // Check if the subcategory exists in updatedFilters[category]
        if (!updatedFilters[category][subcategory]) {
            updatedFilters[category][subcategory] = { filters: [{value: value}] }; // If not, initialize it with the checked value
        } else {
            // Toggle the filter
            const index = updatedFilters[category][subcategory].filters.indexOf(value); // Get the index of the value
            if (index !== -1) {
                // If value is found, remove it
                updatedFilters[category][subcategory].filters.splice(index, 1);
            } else {
                // Otherwise, add it
                updatedFilters[category][subcategory].filters.push({value: value});
            }

            // If filters array becomes empty, remove the subcategory
            if (updatedFilters[category][subcategory].filters.length === 0) {
                delete updatedFilters[category][subcategory];
            }
        }

        // If category becomes empty, remove it
        if (Object.keys(updatedFilters[category]).length === 0) {
            delete updatedFilters[category];
        }

        // Log and return updatedFilters
        console.log(updatedFilters);
        return updatedFilters;
    });
};

  // const toggleAllRowFilters = (category, subcategory) => {
  //   const subcategoryFilters = custInterestData[category][subcategory].filters.map(filter => filter.value);
  //   setSelectedFilters({
  //     ...selectedFilters,
  //     [subcategory]: subcategoryFilters
  //   });
  // };

  const visibleCategories = Object.keys(custInterestData).slice(startIndex, startIndex + 3);

  const classes = {
    table: {
      minWidth: 400,
    },
    academicInstituteCell: {
      width: '40%',
    },
    otherColumnsCell: {
      width: '15%',
    },
    tabContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tab: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    }
  };

  return (
    <div>
      <h2>Customer Interest</h2>
      <Grid container spacing={2} className='content-container'>
        <Grid item xs={12}>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button onClick={() => setConjunction('AND')} variant={conjunctionType === 'AND' ? 'contained' : 'outlined'}>AND</Button>
            <Button onClick={() => setConjunction('OR')} variant={conjunctionType === 'OR' ? 'contained' : 'outlined'}>OR</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ width: '60%' }}
            variant="standard"
            placeholder='Search Label'
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            value={searchValue}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Tabs variant="scrollable" scrollButtons="auto" value={mainTabValue} onChange={handleMainTabChange} className='tab-box'>
            {filteredCategories.map((category, index) => (
              <Tab key={category} value={index} label={
                <div className={classes.tab}>
                  <HelpIcon className={classes.helpIcon} /><br />
                  {category}
                  <HelpIcon className={classes.helpIcon} />
                </div>
              } />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      

      <div>
        <AudienceFooter count={demoCount} onCalculate={handleCalculate}  onNextClick={handleNextTab}/>
      </div>
      {/* Iterate over each category */}
      {filteredCategories.map((category, index) => (
        <TabPanel key={category} value={mainTabValue} index={index}>
          {/* Iterate over each subcategory in the category */}
          {Object.keys(custInterestData[category]).map(subcategory => (
            <div key={subcategory}>
              <Table>
                <TableBody>
                <TableRow>
  <TableCell sx={{ width: '55%' }}>{subcategory}</TableCell>
  {[{ value: 'high' }, { value: 'medium' }, { value: 'low' }].map((filter, index) => (
    <TableCell key={index} sx={{ width: '15%' }}>
      <Checkbox checked={custInterestselectedFilters[category + '__' + subcategory] && custInterestselectedFilters[category + '__' + subcategory].includes(filter.value)} onChange={() => toggleFilter(category, subcategory, filter.value)} />
        <label>{custInterestData[category][subcategory].filters.find(f => f.value === filter.value)?.count || ''}</label>
      
    </TableCell>
  ))}
</TableRow>
                </TableBody>
              </Table>
            </div>
          ))}
        </TabPanel>
      ))}
      {/* <Button onClick={handlePrev} disabled={startIndex === 0}>Previous</Button>
      <Button onClick={handleNext} disabled={startIndex + 3 >= Object.keys(custInterestData).length}>Next</Button> */}
    </div>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

export default CustomerInterest;
