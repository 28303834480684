import deleteData from "../ServiceAPI/deleteData";
import postData from "../ServiceAPI/postData";
import PutData from "../ServiceAPI/putData";
import getData from "../ServiceAPI/getData";

//  const BASE_URL = "http://34.96.173.75:3000/api/v1";
//  const BASE_URL_V2 = "http://34.96.173.75:3000/api/v2";

const BASE_URL = "https://truetarget-dev.com/api/v1";
const BASE_URL_V2 = "https://truetarget-dev.com/api/v2";

const APIS = {
  Registration: async function (formData) {
    return await postData(`${BASE_URL_V2}/user/register`, formData, "");
  },

  CreatePassword: async function (formData) {
    return await PutData(`${BASE_URL}/user/createPassword`, formData, "");
  },
  ConfirmEmailResponse: async function (formData) {
    return await postData(`${BASE_URL}/user/confirmEmail`, formData, "");
  },
  ChangeRegistrationUserStatus: async function (formData) {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOnsiZW1haWwiOiJhZG1AeW9wbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJzdGF0dXMiOiJQZW5kaW5nIEFwcHJvdmFsIn0sImlhdCI6MTcwOTIxNDA2Mn0.Hcddfmohe7UeEyzi5z73h3zFN53Kx_m5rWT6clEO0Jg";
    return await PutData(`${BASE_URL}/user/changeStatus`, formData, token);
  },
  Login: async function (formData) {
    return await postData(`${BASE_URL_V2}/user/login`, formData, "");
  },
  ForgotPassword: async function (formData) {
    return await PutData(`${BASE_URL}/user/forgotPassword`, formData, "");
  },
  ResetPassword: async function (formData) {
    return await PutData(`${BASE_URL}/user/resetPassword`, formData, "");
  },
  VerifyOTP: async function (formData) {
    return await postData(`${BASE_URL}/user/verifyOtp`, formData, "");
  },
  CreateProject: async function (formData, token) {
    return await postData(`${BASE_URL}/project/createProject`, formData, token);
  },
  ViewProjectDetail: async function (formData, token) {
    return await postData(
      `${BASE_URL}/project/viewProjectDetails`,
      formData,
      token
    );
  },
  RenameProjectDetail: async function (formData, token) {
    return await PutData(`${BASE_URL}/project/renameProject`, formData, token);
  },
  DeleteProjectDetail: async function (formData, token) {
    return await deleteData(
      `${BASE_URL}/project/deleteProject`,
      formData,
      token
    );
  },
  getCustomerInterest: async function (token) {
    console.log("tk:", token);
    return await getData(
      `${BASE_URL}/audience/getAudienceCustomerInterest`,
      token
    );
  },
  getCommercialRelevant: async function (token) {
    return await getData(
      `${BASE_URL}/audience/getAudienceCommercialRelevant`,
      token
    );
  },
  getDemographics: async function (token) {
    return await getData(`${BASE_URL}/audience/getAudienceDemographics`, token);
  },
  getGeographics: async function (token) {
    return await getData(`${BASE_URL}/customer360/location`, token);
  },
  calculateCountCustomerInterest: async function (formData, token) {
    return await postData(
      `${BASE_URL}/segments/calculateSegmentsCount`,
      formData,
      token
    );
  },
  calculateCountCommercialRelevant: async function (formData, token) {
    return await postData(
      `${BASE_URL}/relevance/calculateCommRelevanceCount`,
      formData,
      token
    );
  },
  calculateCountDemographics: async function (formData, token) {
    return await postData(
      `${BASE_URL}/demographics/calculateDemograpphicsCount`,
      formData,
      token
    );
  },
  calculateCountGeographics: async function (formData, token) {
    return await postData(
      `${BASE_URL}/geographics/calculateGeographicsCount`,
      formData,
      token
    );
  },
  calculateCountLocationTraffic: async function (formData, token) {
    return await postData(
      `${BASE_URL}/geographics/getLocationTraffic`,
      formData,
      token
    );
  },
  calculateCountWebsiteTraffic: async function (formData, token) {
    return await postData(
      `${BASE_URL}/geographics/calculateGeographicsCount`,
      formData,
      token
    );
  },
  createAudience: async function (formData, token) {
    return await postData(`${BASE_URL}/audience/saveAudience`, formData, token);
  },
  getProjectList: async function (token) {
    return await getData(
      `${BASE_URL}/audience/getAudienceCustomerInterest`,
      token
    );
  },
  getAudienceList: async function (token) {
    return await getData(`${BASE_URL_V2}/audience/audienceList`, token);
  },
  getMetaAdsAccounts: async function (token) {
    return await getData(`${BASE_URL_V2}/meta/accounts`, token);
  },
  getGoogleAdsAccounts: async function (token) {
    return await getData(`${BASE_URL_V2}/googleAds/accounts`, token);
  },
  getTikTokAdsAccounts: async function (token) {
    return await getData(`${BASE_URL_V2}/tiktok/accounts`, token);
  },
  pushAudienceGoogle: async (token, data) => {
    return await postData(`${BASE_URL_V2}/googleAds/audiencePush`, data, token);
  },
  pushAudienceMeta: async (token, data) => {
    return await postData(`${BASE_URL_V2}/meta/audiencePush`, data, token);
  },
  pushAudienceTiktok: async (token, data) => {
    return await postData(`${BASE_URL_V2}/tiktok/audiencePush`, data, token);
  },
  getMyAccount: async function (token, userId) {
    return await getData(
      `${BASE_URL}/subscription/getPackageList?userId=${userId}`,
      token
    );
  },
  getChannelSetup: async function (token, userId) {
    // return await getData(
    //   `${BASE_URL}/channel/setup?user_id=${userId}`,
    //   token
    // );

    return await getData(`${BASE_URL}/channel/setup`, token);
  },
  getGoogleOAuth: async function (token, redirectUri) {
    return await getData(
      //`${BASE_URL}/googleAds/oauth/url?redirect_uri=${redirectUri}`,
      `${BASE_URL}/googleAds/oauth/url`,
      token
    );
  },
  postGoogleRefreshToken: async function (token, formData) {
    return await postData(
      `${BASE_URL}/googleAds/refreshtoken`,
      formData,
      token
    );
  },
  getMetaOAuth: async function (token) {
    return await getData(`${BASE_URL}/meta/oauth/url`, token);
  },
  postMetaRefreshToken: async function (token, formData) {
    return await postData(`${BASE_URL}/meta/accesstoken`, formData, token);
  },
  getTiktokOAuth: async function (token) {
    return await getData(`${BASE_URL_V2}/tiktok/oauth/url`, token);
  },
  postTiktokRefreshToken: async function (token, formData) {
    return await postData(`${BASE_URL_V2}/tiktok/accesstoken`, formData, token);
  },
  checkEmailDuplicate: async function (formData) {
    return await postData(`${BASE_URL_V2}/user/email/checkDuplicate`, formData);
  },
  getAudiencePushHistory: async function (token, audienceId) {
    return await getData(
      `${BASE_URL_V2}/audience/pushHistory?audienceId=${audienceId}`,
      token
    );
  },
  getMetaAccounts: async function (token) {
    return await getData(`${BASE_URL_V2}/meta/accounts/load`, token);
  },
};

export default APIS;
