import React, { useEffect, useState, useRef } from "react";
import { Box, Snackbar, IconButton } from "@mui/material";
import APIS from "./APIS";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import { Close } from "@mui/icons-material";

const ChannelStatic = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); 
  const isInitialRender = useRef(true);
  const [errorSnackbar, setErrorSnackbar] = useState("");

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      handleChannel();
    }
  },[]);

  const handleChannel = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlCode = urlParams.get("code");
    const urlCodeTiktok = urlParams.get("auth_code");
    const channelName = localStorage.getItem("channelName");
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    if (channelName === "google") {
      try {
        setLoading(true);
        localStorage.setItem("channelName", "google");
        const queryParams = {
          //user_id: userId,
          oauth_code: urlCode,
          //redirect_uri: "http://localhost:3000/ChannelSetup",
        };
        const response = await APIS.postGoogleRefreshToken(token, queryParams);
        console.log("response channel refresh token", response);
        if (response.status === "success") {
          navigate("/ChannelSetup");
        } else {
          setErrorSnackbar(response?.message || "Some Error Occurred");
          setTimeout(() => {
            navigate("/ChannelSetup");
          }, 3000)
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    if (channelName === "meta") {
      try {
        setLoading(true);
        localStorage.setItem("channelName", "meta");
        const queryParams = {
          //user_id: userId,
          code: urlCode,
        };
        const response = await APIS.postMetaRefreshToken(token, queryParams);
        console.log("response channel refresh token", response);
        if (response.status === "success") {
          navigate("/ChannelSetup");
        } else {
          setErrorSnackbar(response?.message || "Some Error Occurred");
          setTimeout(() => {
            navigate("/ChannelSetup");
          }, 3000)
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }

    if (channelName === "tiktok") {
      try {
        setLoading(true);
        localStorage.setItem("channelName", "tiktok");
        const queryParams = {
          //user_id: userId,
          code: urlCodeTiktok,
        };
        const response = await APIS.postTiktokRefreshToken(token, queryParams);
  
        if (response.status === "success") {
          navigate("/ChannelSetup");
        } else {
          setErrorSnackbar(response?.message || "Some Error Occurred");
          setTimeout(() => {
            navigate("/ChannelSetup");
          }, 3000)
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="inner-wrapper">
            <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "10000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {loading && ( // Show loader if loading is true
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ChannelStatic;
