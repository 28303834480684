import * as React from 'react';
import Navbar from '../Components/navbar';

class Terms extends React.Component {
  constructor(props){
    super(); // exception thrown here when not called
  }

     /** render()- method is used to render HTML */
    render() {
        return (
          <div className='login-card privacy-card'>
               <Navbar></Navbar>
                <h2>Term of Use of True Digital Group</h2>
                <div className='font-md'>
              <div> <b> 1.Introduction</b></div><br></br>

<div>True Digital Group Co., Ltd., and its respective subsidiaries, affiliates, associated companies and jointly controlled entities (collectively, “Company”, “We”, “us”, or “our”) attach great importance to customer privacy, hence we provide this privacy notice to inform our customers (“you”) of our policy with regard to your personal data, the methods on how we collect, use and disclose your personal data, the types of personal data we collected, the purpose of personal data processing, the disclosure of your personal to third parties, the details on security measures in place for your personal data, the retention period of personal data, and the rights you have in accordance with Personal Data Protection Act, B.E. 2562 and other related applicable laws and regulations (“Personal Data Protection Law”)</div>

<div>We may publish a policy in addition to this privacy policy with regard to our specific products and/or services (“Additional Policy”) from time to time. If there is any inconsistency between the terms of the Additional Policy and this Privacy Policy of True Digital Group, then the Additional Policy of the specific products and/or services shall prevail.</div>
​
<div>This Privacy Policy of True Digital Group and the Additional Policy shall apply to all personal data that is processed in relation to our products and/or services in the countries where our products and/or services are being provided, used or subscribed. (collectively, “Privacy Policy”).</div>
​

<div><b>2.Contact Us</b></div>
​
<div>You can contact us at 101 True Digital Park, Griffin Building, 9-12 Floor, Sukhumvit Road, Bangchak, Phra Khanong, Bangkok 10260, Thailand or contact our data protection officer for more information about the Privacy Policy via Dataprivacycenter@truedigital.com.</div>
<div>For TrueID users who reside outside Thailand, please contact our team in your country for more information, the details of which are provided on the Additional Policy of TrueID.</div>

<div>3.The Purpose of the Collection, Use and Disclosure of Personal Data</div>
​
<div>We will collect, use and disclose (collectively referred to as “process”) the personal data only in the case where it is necessary and permitted by law. We will process the personal data provided that there is a lawful basis to do so which includes processing of personal data in case it is necessary for the performance of contract we have with you, it is necessary for compliance with our legal obligations, it is of our legal interests, it is to prevent or suppress a danger to your or any other person’s life, body and health, it is for carrying out a public interest task or for exercising official authority, it is to proceed according to your consent given to us, and/or other lawful basis as provided by the Personal Data Protection Law.</div>
​
<div>The law requires us not to collect sensitive personal data including but not limited to the data relating to the race, ethnicity, political views, ideological belief, religion or philosophy, sexual behavior, criminal history, health data, disability, Labor Union membership data, genetic data, biological data, or any other data which may affect you in the same manner, unless we have your explicit consent. We will not collect or use this type of data without your explicit consent unless the law permits us to do so.</div>
​
<div>Subject to the applicable services and/or products available in the countries under the Additional Policy, we may collect your personal data for processing for the following purposes:</div>
​
<div>3.1 To perform our obligations under the contract we have with you</div>
​
<div>We will process your personal data to deliver our service as applied or requested by you, and/or to fulfill contractual obligations we have with you, including but not limited to the following purposes:</div>
​
<div>For your subscription and sign-in to our account (such as TrueID account).</div>
<div>To authenticate your registration and to verify and authenticate your identity as well as contact channels.</div>
<div>To provide services for which you have subscribed, preferred, interested or requested, and/or to comply with the contract you made with us (such as signing up for TrueID, TrueYou, True Digital Academy, Online Station and other products and services).</div>
<div>To communicate for relevant products and services including sending notifications on service charges, goods and service purchase orders, </div>
​
                </div>
            </div>
        );
    }
}

export default Terms;
