import * as React from "react";
import TabPanel from "../Components/TabPanel";
import Grid from "@mui/material/Grid";
import AudienceList from "./AudienceList";
import CreateAudience from "./CreateAudience";

const Audience = () => {
  const [value, setValue] = React.useState("aud-list");
  return (
    <div className="inner-wrapper">
      <Grid container>
        <Grid item xs={11}>
          <TabPanel value={value} index="aud-list">
            <AudienceList />
          </TabPanel>

          <TabPanel value={value} index="create-aud">
            {/* Content for Tab Two */}
            <CreateAudience></CreateAudience>
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default Audience;
