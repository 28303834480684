import React, { useState } from "react";
import Navbar from "../Components/navbar";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import APIS from "./APIS";
import { useRegisterContext } from "../Components/RegisterContext";
import { Snackbar, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";

function MarketingConsent() {
  const navigate = useNavigate();

  const [registrationStatus, setRegistrationStatus] = useState({
    success: null,
    error: null,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedToggles, setSelectedToggles] = useState(0); // Count of selected toggles

  const location = useLocation();
  const receivedData = location.state;
  const { registerData, setRegisterData } = useRegisterContext();
  const [errorSnackbar, setErrorSnackbar] = useState("");
  //console.log("receivedData:", receivedData);

  // Function to handle toggle changes
  const handleToggleChange = (toggle) => {
    const newSelectedToggles = selectedToggles + (toggle ? 1 : -1); // Increment or decrement count based on toggle state
    setSelectedToggles(newSelectedToggles);
  };

  const handleclick = async () => {
    try {
      setIsButtonDisabled(true);
      const response = await APIS.Registration(receivedData);

      if (response.code == 200) {
        handleLoginAfterRegister();
        setRegisterData({});
        //navigate('/PageInfoModalBox', { state: displaydata });
      } else {
        setRegistrationStatus({ success: null, error: response.message });
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      setRegistrationStatus({ success: null, error: error });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleLoginAfterRegister = async () => {
    try {
      const formData = {
        email: receivedData.email,
        password: receivedData.password,
      };

      const response = await APIS.Login(formData);
      if (response.code == 200) {
        //setLoginStatus({ success: response.message, error: null });
        // Store the token in local storage
        localStorage.setItem("token", response.token);
        localStorage.setItem("loogedIn_User", response.data.email);
        localStorage.setItem("userId", response.data.id);
        navigate("/ChannelSetup");
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      //setLoginStatus({ success: null, error: error });
    }
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={errorSnackbar ? true : false}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
        sx={{ zIndex: "5000" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="login-card privacy-card">
        <Navbar></Navbar>
        {registrationStatus.success && (
          <div className="success-message">{registrationStatus.success}</div>
        )}

        {registrationStatus.error && (
          <div className="error-message">{registrationStatus.error}</div>
        )}

        <h2>Marketing</h2>
        <div className="font-md">
          <div>
            <b>1.Introduction</b>
          </div>
          <br></br>
          <div>
            True Digital Group Co., Ltd., and its respective subsidiaries,
            affiliates, associated companies and jointly controlled entities
            (collectively, “Company”, “We”, “us”, or “our”) attach great
            importance to customer privacy, hence we provide this privacy notice
            to inform our customers (“you”) of our policy with regard to your
            personal data, the methods on how we collect, use and disclose your
            personal data, the types of personal data we collected, the purpose
            of personal data processing, the disclosure of your personal to
            third parties, the details on security measures in place for your
            personal data, the retention period of personal data, and the rights
            you have in accordance with Personal Data Protection Act, B.E. 2562
            and other related applicable laws and regulations (“Personal Data
            Protection Law”)
          </div>
          <br></br>

          <div>
            We may publish a policy in addition to this privacy policy with
            regard to our specific products and/or services (“Additional
            Policy”) from time to time. If there is any inconsistency between
            the terms of the Additional Policy and this Privacy Policy of True
            Digital Group, then the Additional Policy of the specific products
            and/or services shall prevail.
          </div>

          <div>
            This Privacy Policy of True Digital Group and the Additional Policy
            shall apply to all personal data that is processed in relation to
            our products and/or services in the countries where our products
            and/or services are being provided, used or subscribed.
            (collectively, “Privacy Policy”).
          </div>
          <br></br>

          <div>
            <b>2.Contact Us</b>
          </div>
          <br></br>

          <div>
            You can contact us at 101 True Digital Park, Griffin Building, 9-12
            Floor, Sukhumvit Road, Bangchak, Phra Khanong, Bangkok 10260,
            Thailand or contact our data protection officer for more information
            about the Privacy Policy via Dataprivacycenter@truedigital.com.
          </div>

          <div>
            For TrueID users who reside outside Thailand, please contact our
            team in your country for more information, the details of which are
            provided on the Additional Policy of TrueID.
          </div>
          <div>
            3.The Purpose of the Collection, Use and Disclosure of Personal Data
          </div>
        </div>

        <div></div>
        <br></br>
        <div>
          <b>Information storage and access</b>
          <Switch onChange={(e) => handleToggleChange(e.target.checked)} />
        </div>

        <div>
          You can contact us at 101 True Digital Park, Griffin Building, 9-12
          Floor, Sukhumvit Road, Bangchak, Phra Khanong, Bangkok 10260, Thailand
          or contact our data protection officer for more information about the
          Privacy Policy via Dataprivacycenter@truedigital.com.
        </div>
        <div>
          For TrueID users who reside outside Thailand, please contact our team
          in your country for more information, the details of which are
          provided on the Additional Policy of TrueID.
        </div>
        <div>
          3.The Purpose of the Collection, Use and Disclosure of Personal Data
        </div>
        <br></br>

        <div>
          <b>Personalization</b>
          <Switch onChange={(e) => handleToggleChange(e.target.checked)} />
        </div>
        <br></br>
        <div>
          You can contact us at 101 True Digital Park, Griffin Building, 9-12
          Floor, Sukhumvit Road, Bangchak, Phra Khanong, Bangkok 10260, Thailand
          or contact our data protection officer for more information about the
          Privacy Policy via Dataprivacycenter@truedigital.com.
        </div>
        <div>
          For TrueID users who reside outside Thailand, please contact our team
          in your country for more information, the details of which are
          provided on the Additional Policy of TrueID.
        </div>
        <div>
          3.The Purpose of the Collection, Use and Disclosure of Personal Data
        </div>

        {selectedToggles == 2 && receivedData && (
          <>
            {/* Content */}
            <Button
              variant="contained"
              size="medium"
              onClick={handleclick}
              disabled={isButtonDisabled}
            >
              CONTINUE
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default MarketingConsent;
